import React from 'react';
import { useIntl } from 'react-intl';
import { DatedEvidence, Evidence } from '@eas/common-web';
import { FormTemplate } from '@models';
import { EvidenceApiUrl, Permission } from '@enums';
import { useEvidencePermission } from '@utils';
import { ActionBar } from './form-template-actionbar';
import { useColumns } from './form-template-columns';
import { Fields } from './form-template-fields';

export function FormTemplates() {
  const intl = useIntl();
  const columns = useColumns();

  const permissions = useEvidencePermission({
    options: {
      NEW: [Permission.FORM_TEMPLATE_WRITE],
      EDIT: [Permission.FORM_TEMPLATE_WRITE],
      // REMOVE: [Permission.FORM_TEMPLATE_WRITE],
      // REFRESH: [Permission.FORM_TEMPLATE_WRITE],
      // SHARE: [Permission.FORM_TEMPLATE_WRITE],
      // CHECK: [Permission.FORM_TEMPLATE_WRITE],
      // SAVE: [Permission.FORM_TEMPLATE_WRITE],
      // CLOSE: [Permission.FORM_TEMPLATE_WRITE],
    },
  });

  const evidence = DatedEvidence.useDatedEvidence<FormTemplate>({
    version: 4,
    identifier: 'FORM_TEMPLATES',
    apiProps: {
      url: EvidenceApiUrl.FORM_TEMPLATES,
    },
    tableProps: {
      columns,
      tableName: intl.formatMessage({
        id: 'ZSD__FORM_TEMPLATES__TABLE__TITLE',
        defaultMessage: 'Šablóny formulárov',
      }),
      showBulkActionButton: false,
      showReportButton: false,
      defaultSorts: [],
    },
    detailProps: {
      GeneralFieldsComponent: Fields,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ZSD__FORM_TEMPLATES__DETAIL__TITLE',
          defaultMessage: 'Šablóna formulára',
        }),
        showBtn: permissions,
        ActionBar,
      },
    },
  });

  return <Evidence {...evidence} />;
}
