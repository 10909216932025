import React, { useContext } from 'react';
import { forwardRef, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuItem from '@material-ui/core/MenuItem';
import Lock from '@material-ui/icons/Lock';
import LockOpen from '@material-ui/icons/LockOpen';
import { PromptContext, abortableFetch } from '@eas/common-web';
import { updateWorkOrder } from '@modules/work-order/work-order-api';
import { WorkOrder } from '@models';
import { EvidenceApiUrl } from '@enums';

type MenuItemProps = {
  closeMenu: () => void;
  workOrder: WorkOrder;
  afterSubmit: () => void;
};

export const LockWorkOrderMenuItem = forwardRef<HTMLLIElement, MenuItemProps>(
  function LockWorkOrderMenuItem({ closeMenu, workOrder, afterSubmit }, ref) {
    const { testPrompt } = useContext(PromptContext);
    const handleLock = useCallback(async () => {
      // fetch the latest data
      const latest = (await abortableFetch(
        `${EvidenceApiUrl.WORK_ORDERS}/${workOrder.id}`
      ).json()) as WorkOrder;

      if (latest && latest.locked === workOrder.locked) {
        await updateWorkOrder(workOrder.id, {
          ...latest,
          locked: !workOrder.locked,
        }).json();
      }

      afterSubmit();

      closeMenu();
    }, [workOrder.id, workOrder.locked, closeMenu, afterSubmit]);
    return (
      <MenuItem
        ref={ref}
        onClick={() => {
          testPrompt({
            key: `WORK_ORDER_LOCK_ALERT_${workOrder.id}`,
            submitCallback: handleLock,
          });
        }}
      >
        <ListItemIcon>
          {workOrder.locked ? (
            <LockOpen fontSize="small" />
          ) : (
            <Lock fontSize="small" />
          )}
        </ListItemIcon>
        {workOrder.locked ? (
          <FormattedMessage
            id="WORK_ORDER_MENU_ITEM_UNLOCK"
            defaultMessage="Odomknúť"
          />
        ) : (
          <FormattedMessage
            id="WORK_ORDER_MENU_ITEM_LOCK"
            defaultMessage="Zamknúť"
          />
        )}
      </MenuItem>
    );
  }
);
