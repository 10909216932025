import dayjs from 'dayjs';
import {
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import { UserSettingsContext, WebsocketContext } from '@eas/common-web';
import { CalendarHandle } from '@modules/planner/calendar/calendar-types';
import {
  calendarSettingsId,
  calendarSettingsVersion,
} from '@modules/planner/calendar/hooks';
import { TableRowHandlersContext } from '@composite/table/table-row-handlers-context';
import {
  MobileUnit,
  WorkOrder,
  WorkOrderStateChangeWebsocketDto,
  ZsdUser,
} from '@models';
import { CalendarDimensions, EvidenceApiUrl } from '@enums';

export type CachedWorkOrder = {
  data: WorkOrder;
  remove?: boolean;
  id: string;
};

export const useListenToWorkOrderChanges = ({
  getRowId,
  currentDate,
  dimension,
  calendarRef,
  variant,
}: {
  getRowId: (row: WorkOrder) => string;
  currentDate?: number;
  dimension?: CalendarDimensions;
  calendarRef?: RefObject<CalendarHandle>;
  variant: 'table' | 'calendar';
}) => {
  /**
   * cachedRef holds all work orders that have changed since the last refresh
   * (ids received via websocket)
   */
  const cachedRef = useRef<{
    [key: string]: CachedWorkOrder;
  }>({});

  const { getCustomSettings } = useContext(UserSettingsContext);

  const { subscribe, unsubscribe } = useContext(WebsocketContext);
  const { updateRow } = useContext(TableRowHandlersContext);

  useImperativeHandle(
    calendarRef?.current?.clearWebsocketCachedData,
    () => () => {
      cachedRef.current = {};
    },
    []
  );

  const clearCache = useCallback(() => {
    cachedRef.current = {};
  }, []);

  const currentSettings = useMemo(
    () =>
      getCustomSettings(calendarSettingsId, calendarSettingsVersion) as {
        units?: (ZsdUser | MobileUnit)[];
      },
    [getCustomSettings]
  );
  const DELAY = 100;
  useEffect(() => {
    let timeOut: NodeJS.Timeout | undefined;
    const subscription = subscribe(
      '/topic/work-order/state-change',
      async (message) => {
        const msg = JSON.parse(
          message.body
        ) as WorkOrderStateChangeWebsocketDto;

        // if work order was not edited by technician, we don't want refresh data
        if (!msg.editedByTechnician) return;

        if (!msg.id) return;

        timeOut = setTimeout(async () => {
          const response = await fetch(
            `${EvidenceApiUrl.WORK_ORDERS}/${msg.id}`
          );
          if (!response.ok) return;

          const changedWorkOrder = (await response.json()) as WorkOrder;
          if (!changedWorkOrder) return;

          ///////////////

          let remove = false;

          // if we receive mobileUnitId or userId in websocket msg, it means that changed work order was unplanned from this user / MU
          const unplannedFrom = msg.mobileUnitId ?? msg.userId;

          if (variant === 'calendar' && unplannedFrom) {
            const data = {
              data: changedWorkOrder,
              id: changedWorkOrder.id,
              remove: true,
            };

            updateRow(unplannedFrom, data);

            cachedRef.current[changedWorkOrder.id] = data;
            return;
          }

          if (variant === 'calendar') {
            const workOrderUnitId =
              changedWorkOrder.user?.id || changedWorkOrder.mobileUnit?.id;

            // do nothing if user / mobile unit is not in the calendar filter
            if (
              !currentSettings?.units?.find(
                (unit) => unit.id === workOrderUnitId
              )
            ) {
              return;
            }

            if (currentDate) {
              const isVyk = changedWorkOrder.currentState?.code === 'VYK';
              // do nothing if changed WO endTime is before current calendar view start date
              if (
                dayjs(changedWorkOrder.endTime).isBefore(
                  dayjs.unix(currentDate)
                )
              ) {
                if (!isVyk) return;
                remove = true;
              }

              // do nothing if changed WO is after current calendar view end date
              if (
                dayjs(changedWorkOrder.startTime).isAfter(
                  dayjs.unix(currentDate).add(1, dimension)
                )
              ) {
                if (!isVyk) return;
                remove = true;
              }
            }
          }
          ///////////////
          let preWorkOrder: WorkOrder | undefined = undefined;
          if (msg.preWorkOrderId) {
            const responsePre = await fetch(
              `${EvidenceApiUrl.WORK_ORDERS}/${msg.preWorkOrderId}`
            );
            if (responsePre.ok) {
              preWorkOrder = await responsePre.json();
            }
          }

          const data = {
            data: changedWorkOrder,
            remove,
            id: changedWorkOrder.id,
          };

          updateRow(getRowId?.(changedWorkOrder), data);

          cachedRef.current[changedWorkOrder.id] = data;

          if (preWorkOrder) {
            const preData = {
              data: preWorkOrder,
              remove: false,
              id: preWorkOrder.id,
            };
            updateRow(getRowId?.(preWorkOrder), preData);
            cachedRef.current[preWorkOrder.id] = preData;
          }
        }, DELAY);
      }
    );
    return () => {
      if (timeOut) {
        clearTimeout(timeOut);
      }
      if (subscription) {
        unsubscribe(subscription);
      }
    };
  }, [
    subscribe,
    unsubscribe,
    updateRow,
    getRowId,
    variant,
    currentDate,
    dimension,
    currentSettings?.units,
  ]);

  useEffect(() => {
    let timeOut: NodeJS.Timeout | undefined;
    const subscription = subscribe(
      '/topic/work-order/ext-update',
      async (message) => {
        const id = message.body;

        if (!id) return;

        timeOut = setTimeout(async () => {
          const response = await fetch(`${EvidenceApiUrl.WORK_ORDERS}/${id}`);
          if (!response.ok) return;

          const changedWorkOrder = (await response.json()) as WorkOrder;
          if (!changedWorkOrder) return;
          ///////////////
          const data = {
            data: changedWorkOrder,
            id: changedWorkOrder.id,
            remove: false,
          };

          updateRow(getRowId(changedWorkOrder), data);

          cachedRef.current[changedWorkOrder.id] = data;
        }, DELAY);
      }
    );
    return () => {
      if (timeOut) {
        clearTimeout(timeOut);
      }
      if (subscription) {
        unsubscribe(subscription);
      }
    };
  }, [subscribe, unsubscribe, updateRow, getRowId, variant]);

  const resendAllChangedWorkOrders = useCallback(() => {
    if (!Object.keys(cachedRef.current).length) return;

    Object.values(cachedRef.current).forEach((cachedWorkOrder) => {
      updateRow(getRowId(cachedWorkOrder.data), cachedWorkOrder);
    });
  }, [updateRow, getRowId]);

  const refreshRow = useCallback(
    (rowId: string) => {
      if (!Object.keys(cachedRef.current).length) return;

      Object.values(cachedRef.current).forEach((cachedWorkOrder) => {
        const id = getRowId(cachedWorkOrder.data);
        if (id === rowId) updateRow(id, cachedWorkOrder);
      });
    },
    [updateRow, getRowId]
  );

  return {
    resendAllChangedWorkOrders,
    refreshRow,
    clearCache,
    cachedRef,
  };
};
