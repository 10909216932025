import React from 'react';
import { useIntl } from 'react-intl';
import { DictionaryEvidence, EmptyComponent, Evidence } from '@eas/common-web';
import { ControlType } from '@models';
import { EvidenceApiUrl } from '@enums';
import { useColumns } from './control-type-columns';
import { Fields } from './control-type-fields';

export function ControlTypes() {
  const intl = useIntl();
  const columns = useColumns();

  const evidence = DictionaryEvidence.useDictionaryEvidence<ControlType>({
    identifier: 'CONTROL_TYPE',
    apiProps: {
      url: EvidenceApiUrl.CONTROL_TYPES,
    },
    tableProps: {
      columns,
      tableName: intl.formatMessage({
        id: 'ZSD__CONTROL_TYPE__TABLE__TITLE',
        defaultMessage: 'Typy kontroly',
      }),
      showBulkActionButton: false,
      showReportButton: false,
      defaultSorts: [],
    },
    detailProps: {
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ZSD__CONTROL_TYPE__DETAIL__TITLE',
          defaultMessage: 'Typ kontroly',
        }),
        subtitle: (type) => type?.name ?? '',
        showBtn: () => false,
      },
      GeneralFieldsComponent: EmptyComponent,
      FieldsComponent: Fields,
    },
  });

  return <Evidence {...evidence} />;
}
