import React, { useContext, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  DatedEvidence,
  DetailContext,
  DetailHandle,
  DetailMode,
  EvidenceContext,
  FormAutocomplete,
  FormCheckbox,
  FormNumberField,
  FormPanel,
  FormSelect,
  FormTableField,
  FormTextField,
  UserContext,
  useFormSelector,
} from '@eas/common-web';
import { useRoles } from '@modules/role/role-api';
import { AbsenceField } from '@composite/absence-collection/absence-field';
import { AddressFields } from '@composite/address/address-fields';
import { CalendarField } from '@composite/calendar-collection/calendar-field';
import { CalendarExceptionField } from '@composite/calendar-exception-collection/calendar-exception-field';
import { CertificationField } from '@composite/user-certification-collection/user/certification-field';
import { ZsdUser } from '@models';
import { Permission } from '@enums';
import { labelMapper } from '@utils';
import { UserDepartmentFields } from './fields/user-department-fields';
import { UserSectionFields } from './fields/user-section-fields';
import { UserTeamField } from './fields/user-team-field';
import { UserTechnicianFields } from './fields/user-technician-fields';
import { useUserTypes } from './user-api';
import { useColumns } from './user-change-columns';
import {
  ABSENCE_SOURCE,
  CALENDARS_SOURCE,
  CALENDAR_EXCEPTIONS_SOURCE,
} from './user-hooked-sources';

export function Fields() {
  const { hookedSources } = useContext(EvidenceContext);

  const intl = useIntl();

  const { mode, source, isExisting } =
    useContext<DetailHandle<ZsdUser>>(DetailContext);

  const { type } = useFormSelector((values: ZsdUser) => ({
    type: values.type,
  }));

  const { hasPermission } = useContext(UserContext);

  const rolesSource = useRoles();
  const userTypesSource = useUserTypes();

  const absenceSource = hookedSources[ABSENCE_SOURCE]?.source;
  const absencePreFilters = useMemo(
    () => absenceSource.getParams().filters,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const calendarSource = hookedSources[CALENDARS_SOURCE]?.source;
  const calendarPreFilters = useMemo(
    () => calendarSource.getParams().filters,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const calendarExceptionSource =
    hookedSources[CALENDAR_EXCEPTIONS_SOURCE]?.source;
  const calendarExceptionPreFilters = useMemo(
    () => calendarSource.getParams().filters,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const columns = useColumns();

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ZSD__USERS__DETAIL__PANEL__GENERAL"
            defaultMessage="Všeobecné"
          />
        }
      >
        <FormNumberField
          name="hrId"
          required={true}
          label={
            <FormattedMessage
              id="ZSD__USERS__DETAIL__FIELD_LABEL__HR_ID"
              defaultMessage="Osobné číslo"
            />
          }
        />
        <FormTextField
          name="firstName"
          required={true}
          label={
            <FormattedMessage
              id="ZSD__USERS__DETAIL__FIELD_LABEL__FIRSTNAME"
              defaultMessage="Meno"
            />
          }
        />
        <FormTextField
          name="lastName"
          required={true}
          label={
            <FormattedMessage
              id="ZSD__USERS__DETAIL__FIELD_LABEL__LASTNAME"
              defaultMessage="Priezvisko"
            />
          }
        />
        <FormTextField
          name="phoneNumber"
          label={
            <FormattedMessage
              id="ZSD__USERS__DETAIL__FIELD_LABEL__PHONE_NUMBER"
              defaultMessage="Telefónne číslo"
            />
          }
        />
        <FormTextField
          name="username"
          required={true}
          label={
            <FormattedMessage
              id="ZSD__USERS__DETAIL__FIELD_LABEL__USERNAME"
              defaultMessage="Prihlasovací KID"
            />
          }
        />
        <FormTextField
          name="kid"
          required={true}
          label={
            <FormattedMessage
              id="ZSD__USERS__DETAIL__FIELD_LABEL__KID"
              defaultMessage="Informatívny KID"
            />
          }
        />

        <FormSelect
          name="type"
          source={userTypesSource}
          valueIsId={true}
          required={true}
          helpLabel={intl.formatMessage({
            id: 'ZSD__USERS__DETAIL__FIELD_HELP__TYPE',
            defaultMessage:
              'Slúži k priradeniu používateľa k regionálnemu členeniu (napr. technika pôjde priradiť k pracovisku)',
          })}
          label={
            <FormattedMessage
              id="ZSD__USERS__DETAIL__FIELD_LABEL__TYPE"
              defaultMessage="Typ používateľa"
            />
          }
        />

        {/* relevant for TECHINICIAN and COORDINATOR */}
        <UserDepartmentFields />

        {/* relevant for TECHNICIAN */}
        <UserTechnicianFields />

        {/* relevant for DISPATCHER */}
        <UserTeamField />

        {/* relevant for MANAGEMENT */}
        <UserSectionFields />

        <FormAutocomplete
          name="roles"
          source={rolesSource}
          multiple={true}
          labelMapper={labelMapper}
          helpLabel={intl.formatMessage({
            id: 'ZSD__USERS__DETAIL__FIELD_HELP__ROLE',
            defaultMessage:
              'Slúži na určenie právomocí v systéme (nastavuje, ktoré dáta môže používateľ zobrazovať alebo upravovať)',
          })}
          label={
            <FormattedMessage
              id="ZSD__USERS__DETAIL__FIELD_LABEL__ROLE"
              defaultMessage="Skupina oprávnení"
            />
          }
        />
        <FormCheckbox
          name="isExternal"
          label={
            <FormattedMessage
              id="ZSD__USERS__DETAIL__FIELD_LABEL__IS_EXTERNAL"
              defaultMessage="Externá osoba"
            />
          }
        />
        <FormTextField
          name="startingPoint.latitude"
          label={
            <FormattedMessage
              id="ZSD__USERS__DETAIL__FIELD_LABEL__LATITUDE"
              defaultMessage="Zemepisná šírka - GPS východiskového bodu"
            />
          }
        />
        <FormTextField
          name="startingPoint.longitude"
          label={
            <FormattedMessage
              id="ZSD__USERS__DETAIL__FIELD_LABEL__LONGITUDE"
              defaultMessage="Zemepisná dĺžka - GPS východiskového bodu"
            />
          }
        />
        <DatedEvidence.DatedFields />
      </FormPanel>

      <FormPanel
        label={
          <FormattedMessage
            id="ZSD__USERS__DETAIL__PERSONAL_ADDRESS"
            defaultMessage="Adresa bydliska"
          />
        }
        defaultExpanded={false}
        expandable={true}
      >
        <AddressFields addressKey="personalAddress" />
      </FormPanel>

      <FormPanel
        label={
          <FormattedMessage
            id="ZSD__USERS__DETAIL__BUSINESS_ADDRESS"
            defaultMessage="Služobná adresa"
          />
        }
        defaultExpanded={false}
        expandable={true}
      >
        <AddressFields addressKey="businessAddress" />
      </FormPanel>

      {type === 'TECHNICIAN' && isExisting && mode === DetailMode.VIEW && (
        <>
          {hasPermission(Permission.ABSENCE_READ) && (
            <AbsenceField
              source={absenceSource}
              refKey="user"
              currentRef={{
                id: source.data?.id ?? '',
              }}
              preFilters={absencePreFilters}
            />
          )}
          {hasPermission(Permission.CALENDAR_READ) && (
            <CalendarField
              source={calendarSource}
              refKey="user"
              currentRef={{
                id: source.data?.id ?? '',
              }}
              preFilters={calendarPreFilters}
            />
          )}
          {hasPermission(Permission.CALENDAR_EXCEPTION_READ) && (
            <CalendarExceptionField
              source={calendarExceptionSource}
              refKey="user"
              currentRef={{
                id: source.data?.id ?? '',
              }}
              preFilters={calendarExceptionPreFilters}
            />
          )}
          {hasPermission(Permission.CERTIFICATION_READ) && (
            <CertificationField
              value={source.data?.certifications ?? []}
              refKey="user"
              currentRef={{
                id: source.data?.id ?? '',
              }}
            />
          )}
        </>
      )}

      <FormPanel
        label={
          <FormattedMessage
            id="ZSD__USERS__DETAIL__FIELD_LABEL__CHANGES"
            defaultMessage="História zmien"
          />
        }
        expandable={true}
        defaultExpanded={false}
      >
        <FormTableField
          name={'changes'}
          disabled={true}
          labelOptions={{ hide: true }}
          layoutOptions={{ noSpacing: true }}
          showDetailBtnCond={() => false}
          showToolbar={false}
          columns={columns}
        />
      </FormPanel>
    </>
  );
}
