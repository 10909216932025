import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { ArchivedFile } from 'src/models/archived-file';
import {
  FormCheckbox,
  FormContext,
  FormCustomField,
  FormLocalDateField,
  FormPanel,
  FormTextArea,
  FormTextField,
  TextField,
} from '@eas/common-web';
import { Redirect } from '@components/redirect/redirect';
import { ZsdUser } from '@models';

export function formatUserName(user: ZsdUser | undefined | null) {
  if (!user) return '-';
  return `${user.firstName ?? '?'} ${user.lastName ?? '?'}`;
}

function DefectUpdateDialog() {
  const { getFieldValues } = useContext(FormContext);

  return (
    <>
      <FormPanel
        defaultExpanded={true}
        label={
          <FormattedMessage
            id="ZSD__ABSENCE__DETAIL__FIELD_LABEL__DETAILS"
            defaultMessage="Detail"
          />
        }
      >
        <FormTextField
          disabled
          name="defectId"
          label={
            <FormattedMessage
              id="ZSD__DEFECT__DETAIL__FIELD_LABEL__"
              defaultMessage="ID nedostatku"
            />
          }
        />
        <FormTextField
          disabled
          name="component.name"
          label={
            <FormattedMessage
              id="ZSD__DEFECT__DETAIL__FIELD_LABEL__"
              defaultMessage="Komponenta"
            />
          }
        />
        <FormTextField
          disabled
          name="type.name"
          label={
            <FormattedMessage
              id="ZSD__DEFECT__DETAIL__FIELD_LABEL__"
              defaultMessage="Typ"
            />
          }
        />
        <FormTextField
          disabled
          name="type.category"
          label={
            <FormattedMessage
              id="ZSD__DEFECT__DETAIL__FIELD_LABEL__"
              defaultMessage="Kategória"
            />
          }
        />
        <FormTextArea
          disabled
          name="description"
          minRows={1}
          label={
            <FormattedMessage
              id="ZSD__DEFECT__DETAIL__FIELD_LABEL__"
              defaultMessage="Popis"
            />
          }
        />

        <FormCustomField name={`discoveredBy`} label={'Autor'} disabled>
          <TextField
            value={formatUserName(getFieldValues().discoveredBy)}
            disabled
          />
        </FormCustomField>
        <FormLocalDateField
          name="discoveryDate"
          disabled
          label={
            <FormattedMessage
              id="ZSD__DEFECT__DETAIL__FIELD_LABEL__"
              defaultMessage="Dátum zápisu"
            />
          }
        />
        <FormTextField
          disabled
          name="amount"
          label={
            <FormattedMessage
              id="ZSD__DEFECT__DETAIL__FIELD_LABEL__"
              defaultMessage="Množstvo"
            />
          }
        />
        <FormTextField
          disabled
          name="threatLevel"
          label={
            <FormattedMessage
              id="ZSD__DEFECT__DETAIL__FIELD_LABEL__"
              defaultMessage="Miera nebezpečenstva"
            />
          }
        />

        <FormCheckbox
          disabled
          name="solved"
          label={
            <FormattedMessage
              id="ZSD__DEFECT__DETAIL__FIELD_LABEL__"
              defaultMessage="Vyriešené"
            />
          }
        />

        <FormCustomField name="resolvedBy" label="Vyriešil" disabled>
          <TextField
            value={formatUserName(getFieldValues().resolvedBy)}
            disabled
          />
        </FormCustomField>

        <FormLocalDateField
          name="resolutionDate"
          disabled
          label={
            <FormattedMessage
              id="ZSD__DEFECT__DETAIL__FIELD_LABEL__"
              defaultMessage="Dátum vyriešenia"
            />
          }
        />

        <FormTextArea
          disabled
          name="solutionComment"
          minRows={1}
          label={
            <FormattedMessage
              id="ZSD__DEFECT__DETAIL__FIELD_LABEL__"
              defaultMessage="Poznámka k vyriešeniu"
            />
          }
        />

        <FormTextArea
          disabled
          minRows={1}
          name="solutionMethod"
          label={
            <FormattedMessage
              id="ZSD__DEFECT__DETAIL__FIELD_LABEL__"
              defaultMessage="Metóda riešenia"
            />
          }
        />

        <FormCustomField
          //TODO: rework to gallery, or at least nice table
          name="imagesBefore"
          label={
            <FormattedMessage
              id="ZSD__DEFECT__DETAIL__FIELD_LABEL__"
              defaultMessage="Fotografie pred"
            />
          }
          disabled
        >
          {((getFieldValues().imagesBefore ?? []) as ArchivedFile[]).map(
            (archivedFile) => {
              return (
                <Redirect
                  key={archivedFile.id}
                  url="/api/zsd/file"
                  id={`${archivedFile.id}`}
                  showRedirectInCurrent={false}
                />
              );
            }
          )}
        </FormCustomField>
        <FormCustomField
          //TODO: rework to gallery, or at least nice table
          name="imagesAfter"
          label={
            <FormattedMessage
              id="ZSD__DEFECT__DETAIL__FIELD_LABEL__"
              defaultMessage="Fotografie po"
            />
          }
          disabled
        >
          {((getFieldValues().imagesAfter ?? []) as ArchivedFile[]).map(
            (archivedFile) => {
              return (
                <Redirect
                  key={archivedFile.id}
                  url="/api/zsd/file"
                  id={`${archivedFile.id}`}
                  showRedirectInCurrent={false}
                />
              );
            }
          )}
        </FormCustomField>
      </FormPanel>
    </>
  );
}

export default DefectUpdateDialog;
