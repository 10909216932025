import React from 'react';
import { TileLayer, TileLayerProps } from 'react-leaflet';

/**
 * OSM raster tile layer
 *
 * https://www.openstreetmap.org/
 */
export const BasicTileLayer = React.forwardRef<
  L.TileLayer,
  Omit<TileLayerProps, 'url'>
>(function BasicTileLayer(props, ref) {
  return (
    <TileLayer
      ref={ref}
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      minNativeZoom={8}
      maxNativeZoom={19}
      minZoom={8}
      maxZoom={24}
      detectRetina={true}
      {...props}
    />
  );
});
