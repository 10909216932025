import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DictionaryEvidence, FormPanel } from '@eas/common-web';

export function Fields() {
  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ZSD__VOLTAGE_LEVEL__DETAIL__PANEL__GENERAL"
            defaultMessage="Všeobecné"
          />
        }
      >
        <DictionaryEvidence.FieldName />
      </FormPanel>
    </>
  );
}
