import { createContext } from 'react';

export type ClearCacheHandler = () => void;

export type WebsocketCacheControlType = {
  [key: string]: React.MutableRefObject<ClearCacheHandler | null>;
};

export const WebsocketCacheControlContext =
  createContext<WebsocketCacheControlType>({});
