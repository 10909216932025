import { DictionaryEvidence, TableColumn } from '@eas/common-web';
import { MethodOD } from '@models';

export function useColumns(): TableColumn<MethodOD>[] {
  const dictionaryColumns = DictionaryEvidence.useDictionaryColumns<MethodOD>();
  return [
    dictionaryColumns.columnName,
    dictionaryColumns.columnCreated,
    dictionaryColumns.columnUpdated,
  ];
}
