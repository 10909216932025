import dayjs from 'dayjs';
import maxBy from 'lodash/maxBy';
import { WorkOrder } from '@models';

export function getPreviousWorkOrder(
  workOrders: WorkOrder[],
  currentDate: number
) {
  const previousWorkOrders = workOrders?.filter(
    (wo) =>
      dayjs(wo.endTime).unix() < currentDate &&
      dayjs(wo.endTime).isSame(dayjs.unix(currentDate), 'day')
  );

  const previousWorkOrder = maxBy(previousWorkOrders, (wo) =>
    dayjs(wo.endTime).unix()
  );

  return previousWorkOrder;
}
