import { useIntl } from 'react-intl';
import { TableFieldCells, TableFieldColumn } from '@eas/common-web';
import { WorkOrderStateChange } from '@models';

export function useColumns(): TableFieldColumn<WorkOrderStateChange>[] {
  const intl = useIntl();

  return [
    {
      datakey: 'modifiedBy',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__MODIFIED_BY',
        defaultMessage: 'Vykonané používateľom',
      }),
      width: 150,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      datakey: 'timestamp',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__TIMESTAMP',
        defaultMessage: 'Časová značka',
      }),

      width: 150,
      CellComponent: TableFieldCells.DateTimeCell,
    },
    {
      datakey: 'action',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__ACTION',
        defaultMessage: 'Akcia',
      }),
      width: 150,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      datakey: 'fullName',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__FULL_NAME',
        defaultMessage: 'Meno upraveného používateľa',
      }),
      width: 150,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      datakey: 'certifications',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__CERTIFICATIONS',
        defaultMessage: 'Zručnosti platné v daný moment',
      }),
      width: 250,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      datakey: 'departments',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__DEPARTMENTS',
        defaultMessage: 'Mena obsluhovaných pracovísk',
      }),
      width: 250,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      datakey: 'changedAssignment',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__CHANGED_ASSIGNMENT',
        defaultMessage: 'Zmena priradenia do čaty',
      }),
      width: 250,
      CellComponent: TableFieldCells.TextCell,
    },
  ];
}
