import React from 'react';
import { usePrompts } from '@eas/common-web';
import { GPSPoint, WorkOrder } from '@models';
import { UnitPlanForm } from './unit-plan-form';

export function useUnitPlan({
  id,
  startingPoint,
  workOrders,
}: {
  id: string;
  startingPoint?: GPSPoint;
  workOrders: (WorkOrder | undefined)[];
}) {
  usePrompts(
    [
      {
        key: `UNIT_PLAN_DIALOG_${id}`,
        dialogTitle: 'Plán cesty',
        dialogWidth: 1200,
        dialogText: (
          <UnitPlanForm
            id={id}
            workOrders={
              (workOrders.filter(
                (wo) => !!wo && wo.currentState?.code !== 'PRE'
              ) ?? []) as WorkOrder[]
            }
            unitStartingPoint={startingPoint}
            addStartingPoint
            addReturnPoint
          />
        ),
      },
    ],
    []
  );
}
