import dayjs from 'dayjs';
import React, { useContext, useMemo } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { CalendarContext } from '@modules/plan/calendar/calendar-context';
import { PlanCalendarDataType } from '@modules/plan/calendar/calendar-types';
import { getTaskStart } from '@modules/plan/calendar/utils/get-task-start';
import { getTaskWidth } from '@modules/plan/calendar/utils/get-task-width';
import { getAbsences } from '@composite/calendar/utils';

export function AbsenceBar({ barData }: { barData: PlanCalendarDataType }) {
  const { settings } = useContext(CalendarContext);
  const theme = useTheme();

  const absences = useMemo(() => {
    return barData.shifts
      ?.map((shift, index) => {
        if (shift?.isBlockingException) {
          return undefined;
        }
        return getAbsences(
          barData.absences,
          dayjs.unix(barData.date).add(index, 'day').format('YYYY-MM-DD')
        );
      })
      .flat();
  }, [barData.absences, barData.date, barData.shifts]);

  if (!absences || absences.length === 0) {
    return <></>;
  }

  return (
    <>
      {absences.map((absence, index) => {
        if (!absence) return null;
        const start = getTaskStart(
          barData.date,
          dayjs(absence.fromDate),
          settings
        );
        const width = getTaskWidth(
          dayjs(absence.fromDate),
          dayjs(absence.toDate),
          0,
          settings
        );
        return (
          <div
            key={index}
            style={{
              position: 'absolute',
              height: '100%',
              width: width ?? 0,
              zIndex: 1,
              left: start ?? 0,
              background: `repeating-linear-gradient(135deg, #FFF 0 3px, ${theme.palette.error.light}64 0px 5px )`,
              backgroundSize: 35,
            }}
          />
        );
      })}
    </>
  );
}
