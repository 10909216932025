import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  AuthoredEvidence,
  DictionaryAutocomplete,
  FormAutocomplete,
  FormPanel,
  FormTextField,
  useAutocompleteSource,
} from '@eas/common-web';
import { EvidenceApiUrl } from '@enums';

export function Fields() {
  const workOrderTypesSource = useAutocompleteSource<DictionaryAutocomplete>({
    url: `${EvidenceApiUrl.WORK_ORDER_TYPES}/autocomplete`,
  });
  const methodODSource = useAutocompleteSource<DictionaryAutocomplete>({
    url: `${EvidenceApiUrl.METHOD_OD}/autocomplete`,
  });
  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ZSD__DEVICE_TYPE__DETAIL__PANEL__GENERAL"
            defaultMessage="Všeobecné"
          />
        }
      >
        <FormTextField
          name="name"
          label={
            <FormattedMessage
              id="ZSD__DEVICE_TYPE__DETAIL__FIELD_LABEL__NAME"
              defaultMessage="Názov"
            />
          }
          required
        />

        <AuthoredEvidence.AuthoredFields />
      </FormPanel>
      <FormPanel
        label={
          <FormattedMessage
            id="ZSD__DEVICE_TYPE__DETAIL__PANEL__WORK_ORDER_TYPE"
            defaultMessage="Typ pracovného príkazu"
          />
        }
      >
        <FormAutocomplete source={workOrderTypesSource} name="workOrderType" />
      </FormPanel>
      <FormPanel
        label={
          <FormattedMessage
            id="ZSD__DEVICE_TYPE__DETAIL__PANEL__METHOD_ODS"
            defaultMessage="Spôsoby odpojenia"
          />
        }
      >
        <FormAutocomplete source={methodODSource} name="methodODs" multiple />
      </FormPanel>
    </>
  );
}
