import {
  DictionaryEvidence,
  TableCells,
  TableColumn,
  TableFilterCells,
} from '@eas/common-web';
import { useWorkOrderTypes } from '@modules/work-order-type/work-order-type-api';
import { DeviceType } from '@models';

export function useColumns(): TableColumn<DeviceType>[] {
  const dictionaryColumns =
    DictionaryEvidence.useDictionaryColumns<DeviceType>();
  return [
    dictionaryColumns.columnName,
    {
      datakey: 'workOrderType.name',
      sortkey: 'workOrderType.name',
      filterkey: 'workOrderType.id',
      name: 'Typ pracovného príkazu',
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      FilterComponent:
        TableFilterCells.useFilterAutocompleteCellFactory(useWorkOrderTypes),
    },
    dictionaryColumns.columnCreated,
    dictionaryColumns.columnUpdated,
  ];
}
