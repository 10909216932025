import { useIntl } from 'react-intl';
import { DictionaryEvidence, TableCells, TableColumn } from '@eas/common-web';
import { ActivityType } from '@models';

export function useColumns(): TableColumn<ActivityType>[] {
  const intl = useIntl();

  const dictionaryColumns =
    DictionaryEvidence.useDictionaryColumns<ActivityType>();

  return [
    dictionaryColumns.columnName,
    {
      datakey: 'revisionUnitType',
      sortkey: 'revisionUnitType',
      filterkey: 'revisionUnitType',
      name: intl.formatMessage({
        id: 'ZSD__ACTIVITY_TYPE__TABLE__COLUMN__REVISION_UNIT_TYPE',
        defaultMessage: 'Typ revízneho celku',
      }),
      sortable: true,
      filterable: true,
      width: 250,
      CellComponent: TableCells.TextCell,
    },
    {
      datakey: 'controlType.name',
      sortkey: 'controlType.name',
      filterkey: 'controlType.name',
      name: intl.formatMessage({
        id: 'ZSD__ACTIVITY_TYPE__TABLE__COLUMN__CONTROL_TYPE',
        defaultMessage: 'Typ kontroly',
      }),
      sortable: true,
      filterable: true,
      width: 200,
      CellComponent: TableCells.TextCell,
    },
    {
      datakey: 'technicalObjectType.name',
      sortkey: 'technicalObjectType.name',
      filterkey: 'technicalObjectType.name',
      name: intl.formatMessage({
        id: 'ZSD__ACTIVITY_TYPE__TABLE__COLUMN__TECHNICAL_OBJECT_TYPE',
        defaultMessage: 'Typ technického objektu',
      }),
      sortable: true,
      filterable: true,
      width: 250,
      CellComponent: TableCells.TextCell,
    },
  ];
}
