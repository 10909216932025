import React from 'react';
import { useIntl } from 'react-intl';
import {
  DatedEvidence,
  DictionaryEvidence,
  EmptyComponent,
  Evidence,
} from '@eas/common-web';
import { Certification } from '@models';
import { EvidenceApiUrl, Permission } from '@enums';
import { useEvidencePermission } from '@utils';
import { Fields } from './certification-fields';
import { useCertificationHookedSources } from './certification-hooked-sources';
import { useValidationSchema } from './certification-schema';

export function Certifications() {
  const intl = useIntl();
  const validationSchema = useValidationSchema();
  const columns = DictionaryEvidence.useDictionaryColumns<Certification>();

  const btnPermissions = useEvidencePermission({
    options: {
      NEW: [Permission.CERTIFICATION_WRITE],
      EDIT: [Permission.CERTIFICATION_WRITE],
      REMOVE: [Permission.CERTIFICATION_WRITE],
    },
  });

  const evidence = DatedEvidence.useDatedEvidence<Certification>({
    identifier: 'CERTIFICATION',
    apiProps: {
      url: EvidenceApiUrl.CERTIFICATIONS,
      hookedSources: useCertificationHookedSources,
    },
    tableProps: {
      columns: [columns.columnName],
      tableName: intl.formatMessage({
        id: 'ZSD__CERTIFICATIONS__TABLE__TITLE',
        defaultMessage: 'Zručnosti technikov',
      }),
      showBulkActionButton: false,
      showReportButton: false,
      defaultSorts: [],
    },
    detailProps: {
      FieldsComponent: Fields,
      GeneralFieldsComponent: EmptyComponent,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ZSD__CERTIFICATIONS__DETAIL__TITLE',
          defaultMessage: 'Zručnosť technika',
        }),
        subtitle: (certification) => certification?.name ?? '',
        showBtn: btnPermissions,
      },
      validationSchema,
    },
  });

  return <Evidence {...evidence} />;
}
