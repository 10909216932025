import { useIntl } from 'react-intl';
import {
  DomainObject,
  TableFieldCells,
  TableFieldColumn,
} from '@eas/common-web';

export function useColumns<T extends DomainObject>(): TableFieldColumn<T>[] {
  const intl = useIntl();

  return [
    {
      name: intl.formatMessage({
        id: 'ZSD__DEFECTS__TABLE__COLUMN__ID',
        defaultMessage: 'ID',
      }),
      datakey: 'defectId',
      sortkey: 'defectId',
      width: 100,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      name: intl.formatMessage({
        id: 'ZSD__DEFECTS__TABLE__COLUMN__SOLVED',
        defaultMessage: 'Vyriešený',
      }),
      datakey: 'solved',
      sortkey: 'solved',
      width: 100,
      CellComponent: TableFieldCells.BooleanCell,
    },
    {
      name: intl.formatMessage({
        id: 'ZSD__DEFECTS__TABLE__COLUMN__DESCRIPTION',
        defaultMessage: 'Popis',
      }),
      datakey: 'description',
      sortkey: 'description',
      width: 300,
      CellComponent: TableFieldCells.TextCell,
    },
  ];
}
