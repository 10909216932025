import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Checkbox,
  DetailContext,
  FormAutocomplete,
  FormCheckbox,
  FormContext,
  FormCustomField,
  FormNumberField,
  FormPanel,
  FormTextArea,
  FormTextField,
  useFormSelector,
} from '@eas/common-web';
import { useDepartmentAutocomplete } from '@modules/department/department-api';
import { useMobileUnits } from '@modules/mobile-unit/mobile-unit-api';
import { useUsers } from '@modules/user/user-api';
import { Redirect } from '@components/redirect/redirect';
import { WorkOrder, ZsdUser } from '@models';
import { EvidenceBrowserUrl } from '@enums';
import { labelMapper, userLabelMapper } from '@utils';

export function DetailFields() {
  const departments = useDepartmentAutocomplete();
  const mobileUnits = useMobileUnits();
  const users = useUsers();

  const { getFieldValues, setFieldValue } = useContext(FormContext);

  const { inDanger, workOrderType, operationNumber } = useFormSelector(
    (data: WorkOrder) => ({
      inDanger: data.inDanger,
      workOrderType: data.workOrderType,
      operationNumber: data.operationNumber,
    })
  );

  const isZ20010 = workOrderType?.name === 'Z200' && operationNumber === 10;

  // For DEV purposes
  const { isExisting } = useContext(DetailContext);

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ZSD__WORK_ORDERS__DETAIL__PANEL__DETAIL"
            defaultMessage="Detail"
          />
        }
      >
        <FormAutocomplete
          name="department"
          source={departments}
          label={
            <FormattedMessage
              id="ZSD__MOBILE_UNIT__DETAIL__FIELD_LABEL__DEPARTMENT"
              defaultMessage="Pracovisko"
            />
          }
          disabled={isExisting}
        />

        {getFieldValues().mobileUnit ? (
          <FormAutocomplete
            name="mobileUnit"
            source={mobileUnits}
            label={
              <FormattedMessage
                id="ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__ASSIGNED_TO"
                defaultMessage="Priradený k"
              />
            }
            labelMapper={labelMapper}
            disabled
          />
        ) : (
          <FormAutocomplete<ZsdUser>
            name="user"
            source={users}
            label={
              <FormattedMessage
                id="ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__ASSIGNED_TO"
                defaultMessage="Priradený k"
              />
            }
            labelMapper={userLabelMapper}
            disabled
          />
        )}

        <FormTextField
          disabled
          name="systemName"
          label={
            <FormattedMessage
              id="ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__SYSTEM_NAME"
              defaultMessage="Informácia, z ktorého systému PP prišiel"
            />
          }
        />
        <FormCheckbox
          name="locked"
          label={
            <FormattedMessage
              id="ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__LOCKED"
              defaultMessage="Uzamknutý"
            />
          }
        />
        <FormCheckbox
          name="fixed"
          label={
            <FormattedMessage
              id="ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__FIXED"
              defaultMessage="Fixný"
            />
          }
          disabled
        />
        <FormCheckbox
          name="indivisible"
          label={
            <FormattedMessage
              id="ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__INDIVISIBLE"
              defaultMessage="Neprerušitelná práca"
            />
          }
        />
        <FormCheckbox
          disabled
          name="customerAgreed"
          label={
            <FormattedMessage
              id="ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__CUSTOMER_AGREED"
              defaultMessage="Dohodnuté so zákazníkom"
            />
          }
        />
        <FormCustomField
          disabled
          name="inDanger"
          label={
            <FormattedMessage
              id="ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__IN_DANGER"
              defaultMessage="Nebezpečenstvo"
            />
          }
        >
          <Checkbox
            disabled
            value={inDanger === 2}
            onChange={(value) => {
              setFieldValue('inDanger', value ? 2 : 1);
            }}
          />
        </FormCustomField>
        <FormNumberField
          name="risk"
          label={
            <FormattedMessage
              id="ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__RISK"
              defaultMessage="Rizikový OP"
            />
          }
          disabled
        />
        <FormTextField
          disabled
          name="technicalPlaceSap"
          label={
            <FormattedMessage
              id="ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__TECHNICAL_PLACE_SAP"
              defaultMessage="Technické miesto SAP"
            />
          }
        />
        <FormTextField
          disabled
          name="technicalPlaceNumber"
          label={
            <FormattedMessage
              id="ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__TECHNICAL_PLACE__NUMBER_SAP"
              defaultMessage="Číslo technického miesta v SAP"
            />
          }
        />
        <FormTextField
          disabled
          name="standardTextKey"
          label={
            <FormattedMessage
              id="ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__STANDARD_TEXT_KEY"
              defaultMessage="Kľúč štandardného textu"
            />
          }
        />
        <FormTextField
          name="shortDescription"
          label={
            <FormattedMessage
              id="ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__SHORT_TEXT"
              defaultMessage="Krátky text"
            />
          }
          disabled
        />
        <FormTextField
          name="equipmentId"
          label={
            <FormattedMessage
              id="ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__EQUIPMENT_ID"
              defaultMessage="Popis vybavenia - ID"
            />
          }
          disabled
        />
        <FormTextField
          name="equipmentText"
          label={
            <FormattedMessage
              id="ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__EQUIPMENT_TEXT"
              defaultMessage="Popis vybavenia - Text"
            />
          }
          disabled
        />
        <FormCheckbox
          name="expectedMaterial"
          label={
            <FormattedMessage
              id="ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__EXPECTED_MATERIAL"
              defaultMessage="Očakávaný materiál"
            />
          }
        />

        <FormTextField
          name="usedMaterial"
          label={
            <FormattedMessage
              id="ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__USED_MATERIAL"
              defaultMessage="Použitý materiál"
            />
          }
          disabled
        />
        <FormTextField
          name="consumptionPlaceNumber"
          label={
            <FormattedMessage
              id="ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__CONSUMPTION_PLACE_NUMBER"
              defaultMessage="Číslo miesta spotreby"
            />
          }
          disabled
        />
        <FormTextField
          name="consumptionPointTypeDescription"
          label={
            <FormattedMessage
              id="ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__CONSUMPTION_POINT_TYPE_DESCRIPTION"
              defaultMessage="Popis druhu miesta spotreby"
            />
          }
          disabled
        />
        {isZ20010 && (
          <>
            <FormCheckbox
              name="supplyRestored"
              label={
                <FormattedMessage
                  id="ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__SUPPLY_RESTORED"
                  defaultMessage="Dodávka obnovená"
                />
              }
            />
            <FormCheckbox
              name="maintenanceFailure"
              label={
                <FormattedMessage
                  id="ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__MAINTENANCE_FAILURE"
                  defaultMessage="Porucha v údržbe (PVU)"
                />
              }
            />
          </>
        )}
        <FormCustomField
          name="customerSignature?.file"
          label={
            <FormattedMessage
              id="ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__CUSTOMER_SIGNATURE"
              defaultMessage="Podpis zákazníka"
            />
          }
          disabled
        >
          {getFieldValues().customerSignature && (
            <Redirect
              url="/api/zsd/file"
              id={`${getFieldValues().customerSignature.id}`}
              showRedirectInCurrent={false}
            />
          )}
        </FormCustomField>
        <FormCustomField
          name="technicianSignature?.file"
          label={
            <FormattedMessage
              id="ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__TECHNICIAN_SIGNATURE"
              defaultMessage="Podpis technika"
            />
          }
          disabled
        >
          {getFieldValues().technicianSignature && (
            <Redirect
              url="/api/zsd/file"
              id={`${getFieldValues().technicianSignature.id}`}
              showRedirectInCurrent={false}
            />
          )}
        </FormCustomField>
        <FormCustomField
          name="technicianSignature2?.file"
          label={
            <FormattedMessage
              id="ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__TECHNICIAN_SIGNATURE_2"
              defaultMessage="Podpis technika 2"
            />
          }
          disabled
        >
          {getFieldValues().technicianSignature2 && (
            <Redirect
              url="/api/zsd/file"
              id={`${getFieldValues().technicianSignature2.id}`}
              showRedirectInCurrent={false}
            />
          )}
        </FormCustomField>
        <FormCustomField
          name="filledForm"
          label={
            <FormattedMessage
              id="ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__FILLED_FORM"
              defaultMessage="Vyplnený formulár"
            />
          }
          disabled
        >
          {getFieldValues().filledForm && (
            <Redirect
              url={`${EvidenceBrowserUrl.FILLED_FORMS}/${getFieldValues().id}/${
                getFieldValues().filledForm.id
              }/filler`}
              id=""
            />
          )}
        </FormCustomField>
        <FormTextArea
          name="note"
          label={
            <FormattedMessage
              id="ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__NOTE"
              defaultMessage="Poznámka"
            />
          }
        />
        <FormTextArea
          disabled
          name="operationDetail"
          label={
            <FormattedMessage
              id="ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__OPERATION_DETAIL"
              defaultMessage="Dlhý text zákazky"
            />
          }
        />
      </FormPanel>
    </>
  );
}
