import { useIntl } from 'react-intl';
import { DictionaryEvidence, TableCells, TableColumn } from '@eas/common-web';
import { ControlType } from '@models';

export function useColumns(): TableColumn<ControlType>[] {
  const intl = useIntl();
  const dictionaryColumns =
    DictionaryEvidence.useDictionaryColumns<ControlType>();

  return [
    dictionaryColumns.columnName,
    {
      datakey: 'voltageLevel.name',
      sortkey: 'voltageLevel.name',
      filterkey: 'voltageLevel.name',
      name: intl.formatMessage({
        id: 'ZSD__CONTROL_TYPE__TABLE__COLUMN__VOLTAGE_LEVEL',
        defaultMessage: 'Napätie',
      }),
      sortable: true,
      filterable: true,
      width: 150,
      CellComponent: TableCells.TextCell,
    },
  ];
}
