import { useIntl } from 'react-intl';
import {
  DomainObject,
  TableFieldCells,
  TableFieldColumn,
} from '@eas/common-web';

export function useColumns<T extends DomainObject>(): TableFieldColumn<T>[] {
  const intl = useIntl();

  return [
    {
      name: intl.formatMessage({
        id: 'ZSD__MISMATCHES__TABLE__COLUMN__TODO:',
        defaultMessage: 'Atribút',
      }),
      datakey: 'attribute.type.name',
      sortkey: 'attribute.type.name',
      width: 200,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      name: intl.formatMessage({
        id: 'ZSD__MISMATCHES__TABLE__COLUMN__TODO:',
        defaultMessage: 'Stará hodnota',
      }),
      datakey: 'oldValue',
      sortkey: 'oldValue',
      width: 200,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      name: intl.formatMessage({
        id: 'ZSD__MISMATCHES__TABLE__COLUMN__TODO:',
        defaultMessage: 'Nová hodnota',
      }),
      datakey: 'newValue',
      sortkey: 'newValue',
      width: 200,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      name: intl.formatMessage({
        id: 'ZSD__MISMATCHES__TABLE__COLUMN__TODO:',
        defaultMessage: 'Autor',
      }),
      datakey: 'updatedBy.name',
      sortkey: 'updatedBy.name',
      width: 100,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      name: intl.formatMessage({
        id: 'ZSD__MISMATCHES__TABLE__COLUMN__TODO:',
        defaultMessage: 'Dátum zmeny',
      }),
      datakey: 'updated',
      sortkey: 'updated',
      width: 300,
      CellComponent: TableFieldCells.DateCell,
    },
  ];
}
