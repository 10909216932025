import React from 'react';
import { useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import { DatedEvidence, EmptyComponent, Evidence } from '@eas/common-web';
import { WorkOrder } from '@models';
import { EvidenceApiUrl, Permission } from '@enums';
import { useEvidencePermission } from '@utils';
import { multiAbortableFetch } from './../../utils/multi-abortable-fetch';
import { useWorkOrdersAbortableList } from './work-order-api';
import { useColumns } from './work-order-columns';
import { Fields } from './work-order-fields';
import { useValidationSchema } from './work-order-schema';
import { mergeAttributes } from './work-order-utils';

export function WorkOrders() {
  const intl = useIntl();
  const validationSchema = useValidationSchema();

  const btnPermissions = useEvidencePermission({
    options: {
      EDIT: [Permission.WORK_ORDER_WRITE],
      REMOVE: [Permission.WORK_ORDER_WRITE],
    },
  });

  const customGetItem = (api: string, itemId: string) =>
    multiAbortableFetch(
      [
        {
          request: `${api}/${itemId}`,
          opts: {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json' }),
          },
        },
        {
          request: `${EvidenceApiUrl.WORK_ORDER_DYNAMIC_ATTRIBUTES}/${itemId}`,
          opts: {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json' }),
          },
        },
      ],
      mergeAttributes
    );

  const evidence = DatedEvidence.useDatedEvidence<WorkOrder>({
    version: 10,
    identifier: 'WORK_ORDER',
    apiProps: {
      url: EvidenceApiUrl.WORK_ORDERS,
      listItems: useWorkOrdersAbortableList,
      getItem: customGetItem,
    },
    tableProps: {
      columns: useColumns(),
      tableName: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__TABLE__TITLE',
        defaultMessage: 'Pracovné príkazy',
      }),
      showBulkActionButton: false,
      showReportButton: false,
      defaultSorts: [
        {
          field: 'workOrderId',
          datakey: 'workOrderId',
          order: 'ASC',
          type: 'FIELD',
        },
      ],
    },
    detailProps: {
      GeneralFieldsComponent: EmptyComponent,
      FieldsComponent: Fields,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ZSD__WORK_ORDERS__DETAIL__TITLE',
          defaultMessage: 'Pracovný príkaz',
        }),
        showBtn: btnPermissions,
      },
      initNewItem: () => ({
        id: uuidv4(),
        fixed: false,
      }),
      validationSchema,
    },
  });

  return <Evidence {...evidence} />;
}
