export const mobileUnitErrors = {
  INVALID_TIME_INTERVAL: [
    'Pracovný príkaz bol naplánovaný do minulosti',
    'Čas začiatku naplánovania je neskôr ako čas konca naplánovania',
  ],
  OUT_OF_SHIFT_INTERVAL: [
    'Pracovný príkaz bol naplánovaný mimo pracovnej zmeny',
  ],
  ENTITY_NOT_EXIST: ['Pracovný príkaz neexistuje'],
  FIXED_TERM_INVALID_DATE: [
    'Pracovný príkaz nemá nastavený dátum "Dohodnuté od" / "Dohodnuté do"',
    'Dátum na kedy je naplánovaný pracovný príkaz je mimo rozsah "Dohodnuté od" / "Dohodnuté do"',
  ],
  FIXED_TERM_PLANNING_BLOCKED: [
    'V tento deň je blokované naplánovanie pracovných príkazov',
  ],

  FLEXIBLE_TERM_INVALID_DATE: [
    'Pracovný príkaz bol naplánovaný na neskorší čas ako je dohodnutý (atribút "Dohodnuté do")',
  ],
  WORK_ORDER_PLANNING_CONFLICT: [
    'Pracovný príkaz sa prekrýva s iným naplánovaným pracovným príkazom',
  ],
  RESOURCE_NOT_EXISTS: ['V danom termíne nie je dostupná čata'],
  DEPARTMENT_MISMATCH: [
    'Pracovný príkaz má nastavené iné pracovisko ako je pracovisko zdroja',
  ],
  INSUFFICIENT_RESOURCE_SIZE: [
    'Veľkosť čaty je menšia ako vyžaduje pracovný príkaz',
  ],
  MISSING_CERTIFICATION: [
    'Zdroj nemá potrebnú certifikáciu pre vykonanie pracovného príkazu',
  ],
  EXPECTED_MATERIAL: [
    'Pracovný príkaz má nastavený atribút "Očakávaný materiál"',
  ],
};
