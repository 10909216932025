import { Dispatch, SetStateAction, createContext, useState } from 'react';

type MapTechnicalObjectsContextType = {
  showLabels?: boolean;
  setShowLabels?: Dispatch<SetStateAction<boolean>>;
};

export const MapTechnicalObjectsContext =
  createContext<MapTechnicalObjectsContextType>({});

export const useTechnicalObjectsOnMap = ({
  initialShowLabels = true,
}: {
  initialShowLabels?: boolean;
}) => {
  const [showLabels, setShowLabels] = useState(initialShowLabels);
  return {
    showLabels,
    setShowLabels,
  };
};
