import React, { ComponentPropsWithoutRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { ScrollableSource } from '@eas/common-web';
import { CollectionField } from '@components/collection-field/collection-field';
import { DictionaryAutocomplete } from '@models';
import { EvidenceApiUrl } from '@enums';
import { useColumns } from './operation-columns';

interface OperationFieldProps
  extends Partial<
    ComponentPropsWithoutRef<typeof CollectionField<DictionaryAutocomplete>>
  > {
  source: ScrollableSource<DictionaryAutocomplete>;
}

export function OperationField({ source, ...overrides }: OperationFieldProps) {
  return (
    <CollectionField<DictionaryAutocomplete>
      title={
        <FormattedMessage
          id="ZSD__FIELD_LABEL__OPERATIONS"
          defaultMessage="Úkony"
        />
      }
      source={source}
      useColumns={useColumns}
      disabled={false}
      createAction={{
        hide: true,
        getUrl: () => EvidenceApiUrl.OPERATIONS,
      }}
      readAction={{
        getUrl: (collectionId) =>
          `${EvidenceApiUrl.OPERATIONS}/${collectionId}`,
      }}
      updateAction={{
        hide: true,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.OPERATIONS}/${collectionId}`,
      }}
      deleteAction={{
        hide: true,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.OPERATIONS}/${collectionId}`,
      }}
      assignAction={{
        hide: true,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.OPERATIONS}/${collectionId}`,
      }}
      unassignAction={{
        hide: true,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.OPERATIONS}/${collectionId}`,
      }}
      {...overrides}
    />
  );
}
