import L from 'leaflet';
import React from 'react';
import * as ReactLeaflet from 'react-leaflet';
import { GPSPoint } from '@models';
import { CENTER_COORDS } from './constants';

export const MapContainer = React.forwardRef<
  L.Map,
  ReactLeaflet.MapContainerProps & {
    children: React.ReactNode;
    initialCenter?: GPSPoint;
    initialZoom?: number;
  }
>(function MapContainer(
  { initialCenter, initialZoom, children, ...rest },
  ref
) {
  return (
    <ReactLeaflet.MapContainer
      ref={ref}
      center={
        initialCenter
          ? [initialCenter.latitude!, initialCenter.longitude!]
          : CENTER_COORDS
      }
      zoom={initialZoom ?? 8}
      scrollWheelZoom={true}
      style={{ width: '100%', height: '100%' }}
      {...rest}
    >
      {children}
    </ReactLeaflet.MapContainer>
  );
});
