import dayjs from 'dayjs';
import { groupBy } from 'lodash';
import React, { useContext, useEffect, useMemo } from 'react';
import { usePrompts } from '@eas/common-web';
import { PinnedMapContext } from '@composite/map/pinned-map-context';
import { GPSPoint, WorkOrder } from '@models';
import { CalendarDataType } from '../calendar/calendar-types';
import { PlannerContext } from '../planner-context';
import { UnitPlanForm } from './unit-plan-form';

export function useUnitPlan({
  id,
  startingPoint,
  workOrders,
}: {
  id: string;
  startingPoint?: GPSPoint;
  workOrders: (WorkOrder | undefined)[];
}) {
  usePrompts(
    [
      {
        key: `UNIT_PLAN_DIALOG_${id}`,
        dialogTitle: 'Plán cesty',
        dialogWidth: 1200,
        dialogText: (
          <UnitPlanForm
            id={id}
            workOrders={(workOrders.filter((wo) => !!wo) ?? []) as WorkOrder[]}
            unitStartingPoint={startingPoint}
            addStartingPoint
            addReturnPoint
          />
        ),
      },
    ],
    []
  );
}

export function useUnitsDayPlans({ data }: { data: CalendarDataType[] }) {
  const { setOtherData } = useContext(PinnedMapContext);
  const { calendarRef } = useContext(PlannerContext);

  const formattedData = useMemo(
    () =>
      data.map((d) => {
        return {
          id: d.id,
          startingPoint: { latitude: d.latitude, longitude: d.longitude },
          workOrders: groupBy(
            (d.workOrders ?? []).filter(
              (w) => !!w && w.currentState?.code !== 'PRE'
            ) ?? [],
            (wo) => {
              return dayjs(wo?.startTime).format('YYYY-MM-DD');
            }
          ),
        };
      }),
    [data]
  );
  useEffect(() => {
    const grouppedObject = formattedData.map((d) => {
      const newData: { [key: string]: WorkOrder[] } = {};
      Object.keys(d.workOrders).forEach((key) => {
        newData[`UNIT_PLAN_DIALOG_${d.id}_${key}`] = (d.workOrders[key] ??
          []) as WorkOrder[];
      });

      return newData;
    });

    const formatted: Record<string, WorkOrder[]> = {};
    grouppedObject.map((unitPlans) => {
      Object.keys(unitPlans).map((key) => {
        formatted[key] = unitPlans[key];
        formatted[key].sort(
          (a, b) => dayjs(a.startTime).unix() - dayjs(b.startTime).unix()
        );
      });
    });

    setOtherData?.(formatted);

    // dependency calendarRef.current?.settings - we are clearing pinned map data on settings change
    // so we need to recreate it
  }, [formattedData, setOtherData, calendarRef.current?.settings]);

  usePrompts(
    formattedData
      .map((d) =>
        Object.keys(d.workOrders).map((key) => {
          return {
            key: `UNIT_PLAN_DIALOG_${d.id}_${key}`,
            dialogTitle: 'Plán cesty',
            dialogWidth: 1200,
            dialogText: (
              <UnitPlanForm
                id={d.id}
                workOrders={
                  (d.workOrders[key].filter((wo) => !!wo) ?? []) as WorkOrder[]
                }
                unitStartingPoint={d.startingPoint}
                addStartingPoint
                addReturnPoint
              />
            ),
          };
        })
      )
      .flat(),
    [formattedData]
  );

  return;
}
