import L from 'leaflet';
import React, { useCallback } from 'react';
import { GPSPoint } from '@models';

export const useMapControl = () => {
  const mapRef = React.useRef<L.Map>(null);
  const zoomIn = useCallback(() => {
    if (mapRef.current) {
      mapRef.current?.zoomIn?.();
    }
  }, []);
  const zoomOut = useCallback(() => {
    if (mapRef.current) {
      mapRef.current?.zoomOut?.();
    }
  }, []);

  const resetView = useCallback((center: GPSPoint, zoom?: number) => {
    if (mapRef.current) {
      mapRef.current?.setView?.([center.latitude!, center.longitude!], zoom);
    }
  }, []);

  return { mapRef, zoomIn, zoomOut, resetView };
};
