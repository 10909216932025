import React, { ComponentProps } from 'react';
import Button from '@material-ui/core/Button';
import { Tooltip } from '@eas/common-web';

type Props = {
  onClick?: () => void;
  label: React.ReactNode;
  tooltip?: React.ReactNode | string;
} & Partial<ComponentProps<typeof Button>>;

const MapControlButton = ({ onClick, label, tooltip, ...rest }: Props) => {
  return (
    <Tooltip title={tooltip} placement="left">
      <Button
        variant="contained"
        color="secondary"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onClick?.();
        }}
        style={{ minWidth: 0 }}
        {...rest}
      >
        {label}
      </Button>
    </Tooltip>
  );
};

export default MapControlButton;
