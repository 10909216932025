import cn from 'clsx';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import React, { useContext, useRef } from 'react';
import Typography from '@material-ui/core/Typography';
import Lock from '@material-ui/icons/Lock';
import { CalendarContext } from '@modules/plan/calendar/calendar-context';
import { getTaskBoundaries } from '@modules/plan/calendar/utils';
import { getTaskStart } from '@modules/plan/calendar/utils/get-task-start';
import { getTaskWidth } from '@modules/plan/calendar/utils/get-task-width';
import {
  WorkOrderMenu,
  WorkOrderMenuHandle,
} from '@modules/plan/work-order-menu/work-order-menu';
import { getPreviousWorkOrder } from '@composite/calendar/utils';
import { TaskTooltip } from '@composite/work-order/task-tooltip/task-tooltip';
import { WorkOrder } from '@models';
import { useStyles } from './task-styles';
import { TravelTime } from './travel-time';

dayjs.extend(duration);

export function Task({
  workOrder,
  overlayCount,
  position,
  date,
}: {
  workOrder: WorkOrder;
  overlayCount: number;
  position: number;
  date: number;
}) {
  const classes = useStyles();
  const { settings, dataSource } = useContext(CalendarContext);

  const workOrderMenuRef = useRef<WorkOrderMenuHandle>(null);

  const { workOrderStart, workOrderEnd } = getTaskBoundaries(
    date,
    settings,
    workOrder
  );

  const taskSize = getTaskWidth(
    workOrderStart,
    workOrderEnd,
    workOrder.duration ?? 0,
    settings
  );

  if (taskSize === 0) {
    return null;
  }

  const taskStart = getTaskStart(date, workOrderStart, settings);

  const previousWorkOrder = getPreviousWorkOrder(
    (
      dataSource.yAxis?.data?.map((day) => day?.workOrders ?? []).flat() ?? []
    ).filter((wo) => dayjs(wo.endTime).unix() > date),
    dayjs(workOrder.startTime).unix()
  );

  return (
    <>
      <TaskTooltip workOrder={workOrder}>
        <div
          className={cn(classes.root, {
            [classes.isMultiDay]: workOrder.isMultiDay,
            [classes.isLocked]: workOrder.locked,
          })}
          style={{
            left: taskStart,
            width: taskSize,
            height: 30 / overlayCount,
            top: (30 * (position - 1)) / overlayCount,
          }}
          onContextMenu={(e) => {
            e.preventDefault();

            workOrderMenuRef.current?.open(e);
          }}
        >
          {!!workOrder.travelPrevious && (
            <TravelTime orientation="left" seconds={workOrder.travelPrevious} />
          )}
          <div className={classes.content}>
            <div
              className={classes.bullet}
              style={{
                backgroundColor: workOrder.currentState?.color,
              }}
            />
            <Typography variant="body2" className={classes.title}>
              # {workOrder.workOrderId} {workOrder.isMultiDay && '(viacdenný)'}
            </Typography>
            {workOrder.locked && (
              <div
                style={{
                  marginLeft: 4,
                  transform: 'scale(0.7)',
                  color: '#ff000094',
                  marginTop: 2,
                }}
              >
                <Lock fontSize="small" />
              </div>
            )}
          </div>
          {!!workOrder.travelNext && (
            <TravelTime orientation="right" seconds={workOrder.travelNext} />
          )}
        </div>
      </TaskTooltip>
      <WorkOrderMenu
        previousWorkOrder={previousWorkOrder}
        workOrder={workOrder}
        ref={workOrderMenuRef}
      />
    </>
  );
}
