import React from 'react';
import { useIntl } from 'react-intl';
import { DictionaryEvidence, EmptyComponent, Evidence } from '@eas/common-web';
import { TechnicalObjectType } from '@models';
import { EvidenceApiUrl } from '@enums';
import { useColumns } from './technical-object-type-columns';
import { Fields } from './technical-object-type-fields';

export function TechnicalObjectTypes() {
  const intl = useIntl();
  const columns = useColumns();

  const evidence =
    DictionaryEvidence.useDictionaryEvidence<TechnicalObjectType>({
      identifier: 'TECHNICAL_OBJECT_TYPE',
      apiProps: {
        url: EvidenceApiUrl.TECHNICAL_OBJECT_TYPES,
      },
      tableProps: {
        columns,
        tableName: intl.formatMessage({
          id: 'ZSD__TECHNICAL_OBJECT_TYPE__TABLE__TITLE',
          defaultMessage: 'Typy technických objektov',
        }),
        showBulkActionButton: false,
        showReportButton: false,
        defaultSorts: [],
      },
      detailProps: {
        toolbarProps: {
          title: intl.formatMessage({
            id: 'ZSD__TECHNICAL_OBJECT_TYPE__DETAIL__TITLE',
            defaultMessage: 'Typ technického objektu',
          }),
          subtitle: (type) => type?.name ?? '',
          showBtn: () => false,
        },
        GeneralFieldsComponent: EmptyComponent,
        FieldsComponent: Fields,
      },
    });

  return <Evidence {...evidence} />;
}
