import React, {
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import AutoSizer from 'react-virtualized-auto-sizer';
import CachedIcon from '@material-ui/icons/Cached';
import {
  ApiFilterOperation,
  Filter,
  ScrollableSource,
  Table,
  TableContext,
  TableHandle,
  TableToolbarButton,
  UserContext,
} from '@eas/common-web';
import { useColumns } from '@modules/work-order/work-order-columns';
import { useListenToWorkOrderChanges } from '@composite/websocket/listen-to-work-order-changes';
import { WebsocketCacheControlContext } from '@composite/websocket/websocket-cache-control-context';
import { Department, WorkOrder } from '@models';
import { useStyles } from '../planner-styles';
import { BulkUnplanSelectedWorkOrders } from './work-order-bulk-unplan';
import { WorkOrderRow } from './work-order-row';
import { useWorkOrderTableStyles } from './work-order-styles';

export function WorkOrderTable({
  workOrderTableRef,
  dataSource,
  dragging,
}: {
  workOrderTableRef: React.RefObject<TableHandle<WorkOrder>>;
  dataSource: ScrollableSource<WorkOrder>;
  dragging?: boolean;
}) {
  const columns = useColumns();
  const classes = useStyles();
  const useTableStyles = useWorkOrderTableStyles(dragging);
  const intl = useIntl();

  const { resendAllChangedWorkOrders, clearCache } =
    useListenToWorkOrderChanges({
      getRowId: (workOrder) => workOrder?.id ?? '',
      variant: 'table',
    });

  const wsCache = useContext(WebsocketCacheControlContext);

  useImperativeHandle(wsCache['table'], () => clearCache);

  const { user } = useContext(UserContext);

  const defaultPreFilters: Filter[] = useMemo(() => {
    const filters: Filter[] = [];

    if (user?.coordinatorPrimaryDepartment?.id) {
      filters.push({
        operation: ApiFilterOperation.EQ,
        field: 'department.id',
        value: user.coordinatorPrimaryDepartment.id,
      });
    }

    if ((user?.additionalDepartments ?? []).length > 0) {
      (user?.additionalDepartments as Department[]).forEach((d) =>
        filters.push({
          operation: ApiFilterOperation.EQ,
          field: 'department.id',
          value: d.id,
        })
      );
    }

    if (filters.length <= 0) {
      return [];
    }
    return [{ operation: ApiFilterOperation.OR, filters }];
  }, [user]);

  return (
    <div className={classes.bottomWindow}>
      <AutoSizer disableWidth={true} style={{ height: '100%' }}>
        {({ height }) => {
          return (
            <Table
              ref={workOrderTableRef}
              version={8}
              tableId="CALENDAR__WORK_ORDER_TABLE"
              tableName={intl.formatMessage({
                id: 'CALENDAR__WORK_ORDER_TABLE',
                defaultMessage: 'Pracovné príkazy',
              })}
              toolbarProps={{
                before: (
                  <>
                    <ClearCache clearCache={clearCache} />
                    <BulkUnplanSelectedWorkOrders clearCache={clearCache} />
                    <TableToolbarButton
                      label={<CachedIcon />}
                      onClick={() => {
                        clearCache?.();
                        workOrderTableRef?.current?.refresh();
                      }}
                      tooltip={intl.formatMessage({
                        id: 'EAS_TABLE_TOOLBAR_BTN_REFRESH',
                        defaultMessage: 'Obnoviť dáta',
                      })}
                      dataCy="EAS__TABLE__TOOLBAR__REFRESH"
                    />
                  </>
                ),
              }}
              defaultPreFilters={defaultPreFilters}
              columns={columns}
              source={dataSource}
              height={height}
              useStyles={useTableStyles}
              showNamedSettingsButton={true}
              showBulkActionButton={false}
              showSelectBox={true}
              showRefreshButton={false}
              showSearchbar={false}
              showReportButton={false}
              RowComponent={WorkOrderRow}
              showResetSortsButton={true}
              afterItemsRendered={resendAllChangedWorkOrders}
            />
          );
        }}
      </AutoSizer>
    </div>
  );
}

/**
 * Utilitiy component to clear websocket cache on sorts and filters change
 */
function ClearCache({ clearCache }: { clearCache?: () => void }) {
  const { sorts, filtersState } = useContext(TableContext);
  useEffect(() => {
    clearCache?.();
  }, [sorts, filtersState, clearCache]);

  return null;
}
