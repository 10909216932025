import { DictionaryEvidence, TableCells, TableColumn } from '@eas/common-web';
import { Department, FormTemplate, WorkOrderType } from '@models';

export function useColumns(): TableColumn<FormTemplate>[] {
  const { columnName, columnCreated, columnUpdated } =
    DictionaryEvidence.useDictionaryColumns<FormTemplate>();

  return [
    columnName,
    {
      datakey: 'workOrderTypes',
      name: 'Druhy pracovních príkazov',
      width: 200,
      CellComponent: function WorkOrderTypeCell(props) {
        return TableCells.TextCell({
          ...props,
          value: props.value
            .map((workOrderType: WorkOrderType) => workOrderType.name)
            .join(', '),
        });
      },
      sortable: false,
      filterable: false,
    },
    {
      datakey: 'departments',
      name: 'Pracoviská',
      width: 200,
      CellComponent: function DepartmentCell(props) {
        return TableCells.TextCell({
          ...props,
          value: props.value
            .map((department: Department) => department?.name)
            .join(', '),
        });
      },
      sortable: false,
      filterable: false,
    },
    {
      datakey: 'operationNumber',
      name: 'Poradové číslo',
      CellComponent: TableCells.TextCell,
      width: 200,
      sortable: false,
      filterable: false,
    },
    {
      datakey: 'universal',
      name: 'Univerzální',
      CellComponent: TableCells.BooleanCell,
      width: 200,
      sortable: false,
      filterable: false,
    },
    columnCreated,
    columnUpdated,
  ];
}
