import { DrivingInfo } from '../types';

export async function getDrivingInfo(coords: string): Promise<DrivingInfo> {
  try {
    const response = await fetch('/api/zsd/location/route', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        coordinates: coords,
      }),
    });

    const json = await response.json();

    return {
      distance: json.routes[0].distance,
      duration: json.routes[0].duration,
      geometry: json.routes[0].geometry,
      legs: json.routes[0].legs,
    };
  } catch (error) {
    console.error(error);

    return {
      distance: 0,
      duration: 0,
      geometry: {
        type: 'LineString',

        coordinates: [],
      },
      legs: [],
    };
  }
}
