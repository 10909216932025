import { CSSProperties } from 'react';

export enum TechnicalObjectMarkerType {
  // VVN
  VVN_POLE = 'VVN_POLE',
  VVN_PODPERNY_BOD = 'VVN_PODPERNY_BOD',
  VVN_USEK_VEDENIA = 'VVN_USEK_VEDENIA',
  // VN
  VN_USEK_VEDENIA = 'VN_USEK_VEDENIA',
  VN_USEKOVY_ODPAJAC = 'VN_USEKOVY_ODPAJAC',
  VN_PODPERNY_BOD = 'VN_PODPERNY_BOD',
  VN_VEDENIE = 'VN_VEDENIE',

  VN_KABLOVY_SUBOR = 'VN_KABLOVY_SUBOR', // TODO: moze byt kategoria spojka / koncovka

  // NN
  NN_PODPERNY_BOD = 'NN_PODPERNY_BOD',
  NN_SKRINA = 'NN_SKRINA',
  NN_USEK_VEDENIA = 'NN_USEK_VEDENIA',
  NN_MIV = 'NN_MIV',

  NN_KABLOVY_SUBOR = 'NN_KABLOVY_SUBOR', // TODO: moze byt  kategoria spojka / koncovka

  // EST
  EST = 'EST',
  // TS
  TS_UZEMNENIE = 'TS_UZEMNENIE',
  TS_STANICA = 'TS_STANICA',
  TS_NN_ISTICE = 'TS_NN_ISTICE',
  TS_VN_ISTICE = 'TS_VN_ISTICE',

  // hidden - do not render on the map
  VVN_PROFIL = 'VVN_PROFIL',
  NN_PRIPOJNY_OBJEKT = 'NN_PRIPOJNY_OBJEKT',
  TS_MERANIE = 'TS_MERANIE',
  TS_VN_POLE = 'TS_VN_POLE',
  TS_VN_ROZVADZAC = 'TS_VN_ROZVADZAC',
  TS_TRANSFORMATOR = 'TS_TRANSFORMATOR',
  TS_NN_ROZVADZAC = 'TS_NN_ROZVADZAC',
  VVN_VEDENIE = 'VVN_VEDENIE', // viz komentar Gulisova https://jira.zsdis.sk/browse/FSMS-437

  //fallback
  UNKNOWN = 'UNKNOWN',
}

export type TechnicalObjectSVGMarkersListType =
  | TechnicalObjectMarkerType.VVN_PODPERNY_BOD
  | TechnicalObjectMarkerType.VN_USEKOVY_ODPAJAC
  | TechnicalObjectMarkerType.VN_PODPERNY_BOD
  | TechnicalObjectMarkerType.NN_PODPERNY_BOD
  | TechnicalObjectMarkerType.NN_SKRINA
  | TechnicalObjectMarkerType.NN_MIV
  | TechnicalObjectMarkerType.EST
  | TechnicalObjectMarkerType.TS_NN_ISTICE
  | TechnicalObjectMarkerType.TS_VN_ISTICE
  | TechnicalObjectMarkerType.TS_UZEMNENIE
  | TechnicalObjectMarkerType.TS_STANICA
  | TechnicalObjectMarkerType.UNKNOWN;

export const technicalObjectSVGMarkersList: TechnicalObjectSVGMarkersListType[] =
  [
    TechnicalObjectMarkerType.VVN_PODPERNY_BOD,
    TechnicalObjectMarkerType.VN_USEKOVY_ODPAJAC,
    TechnicalObjectMarkerType.VN_PODPERNY_BOD,
    TechnicalObjectMarkerType.NN_PODPERNY_BOD,
    TechnicalObjectMarkerType.NN_SKRINA,
    TechnicalObjectMarkerType.NN_MIV,
    TechnicalObjectMarkerType.EST,
    TechnicalObjectMarkerType.TS_NN_ISTICE,
    TechnicalObjectMarkerType.TS_VN_ISTICE,
    TechnicalObjectMarkerType.TS_UZEMNENIE,
    TechnicalObjectMarkerType.TS_STANICA,
    TechnicalObjectMarkerType.UNKNOWN,
  ];

export const technicalObjectSDOList: TechnicalObjectMarkerType[] = [
  TechnicalObjectMarkerType.VVN_POLE,
  TechnicalObjectMarkerType.VVN_USEK_VEDENIA,
  TechnicalObjectMarkerType.VN_USEK_VEDENIA,
  TechnicalObjectMarkerType.VN_VEDENIE,
  TechnicalObjectMarkerType.NN_USEK_VEDENIA,
];

// Do not render on the map
export const technicalObjectHiddenList: TechnicalObjectMarkerType[] = [
  TechnicalObjectMarkerType.VVN_PROFIL,
  TechnicalObjectMarkerType.VVN_VEDENIE,
  TechnicalObjectMarkerType.NN_PRIPOJNY_OBJEKT,
  TechnicalObjectMarkerType.TS_MERANIE,
  TechnicalObjectMarkerType.TS_VN_POLE,
  TechnicalObjectMarkerType.TS_VN_ROZVADZAC,
  TechnicalObjectMarkerType.TS_TRANSFORMATOR,
  TechnicalObjectMarkerType.TS_NN_ROZVADZAC,
];

export const TO_MAP_COLORS: { [key: string]: string } = {
  VVN: '#00FFFF',
  VN: '#FF0000',
  NN: '#00FF00',
  GND: '#ff00ff',
  TS: '#000000',
};

export type SVGDefinition = {
  jsx: JSX.Element;
  width: number;
  height: number;
  scale: number;
  centerAnchorFactors?: [number, number];
  label?: string;
  labelPosition?: 'top' | 'bottom';
  style: CSSProperties;
};
