import React from 'react';
import { useIntl } from 'react-intl';
import { DictionaryEvidence, EmptyComponent, Evidence } from '@eas/common-web';
import { ActivityType } from '@models';
import { EvidenceApiUrl } from '@enums';
import { useColumns } from './activity-type-columns';
import { Fields } from './activity-type-fields';

export function ActivityTypes() {
  const intl = useIntl();
  const columns = useColumns();

  const evidence = DictionaryEvidence.useDictionaryEvidence<ActivityType>({
    identifier: 'ACTIVITY_TYPE',
    apiProps: {
      url: EvidenceApiUrl.ACTIVITY_TYPES,
    },
    tableProps: {
      columns,
      tableName: intl.formatMessage({
        id: 'ZSD__ACTIVITY_TYPE__TABLE__TITLE',
        defaultMessage: 'Typy činností',
      }),
      showBulkActionButton: false,
      showReportButton: false,
      defaultSorts: [],
    },
    detailProps: {
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ZSD__ACTIVITY_TYPE__DETAIL__TITLE',
          defaultMessage: 'Typ činnosti',
        }),
        subtitle: (type) => type?.name ?? '',
        showBtn: () => false,
      },
      GeneralFieldsComponent: EmptyComponent,
      FieldsComponent: Fields,
    },
  });

  return <Evidence {...evidence} />;
}
