import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  isMultiDay: {
    background: `${theme.palette.highlight}!important`,
  },
  isLocked: {
    border: `1px solid ${theme.palette.error.main}`,
  },
  root: {
    position: 'absolute',
    height: '30px',
    background: '#FFF',
    zIndex: 100,
    borderRadius: 2,
    marginTop: '5px',
    boxShadow: `2px 2px 10px 0px ${theme.palette.grey[400]}`,

    '&:hover': {
      background: theme.palette.grey[100],
    },

    '&:before': {
      content: '""',
      position: 'absolute',
      top: 2,
      left: 0,
      borderRadius: 2,
      width: 6,
      height: 'calc(100% - 4px)',
      background: theme.palette.grey[300],
      border: `1px solid ${theme.palette.grey[500]}`,
    },

    '&:after': {
      content: '""',
      position: 'absolute',
      top: 2,
      right: 0,
      borderRadius: 2,
      width: 6,
      height: 'calc(100% - 4px)',
      background: theme.palette.grey[300],
      border: `1px solid ${theme.palette.grey[500]}`,
    },
  },
  content: {
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    padding: 0,
    marginTop: '3px',
    height: 'calc(100% - 4px)',
  },
  bullet: {
    width: 12,
    height: 12,
    borderRadius: 12,
    marginRight: 8,
    marginLeft: 14,
    flexShrink: 0,
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: theme.palette.grey[700],
  },
  tooltip: {
    whiteSpace: 'pre-line',
    display: 'flex',
    flexDirection: 'column',
    width: 'max-content',
  },
  tooltipContent: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
    justifyContent: 'space-between',
  },
}));
