import { GeoJSON as LeafletGeoJSON } from 'leaflet';
import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { Panel, PromptProvider } from '@eas/common-web';
import {
  BRATISLAVA_COORDS_GPS_POINT,
  BasicTileLayer,
  GeoJSON,
  MapContainer,
  WorkOrderMarker,
} from '@composite/map';
import { Leg } from '@composite/map/types';
import { getDrivingInfo } from '@composite/map/utils/get-driving-info';
import { GPSPoint, WorkOrder } from '@models';
import {
  addStartingPointCoords,
  getPointsWithId,
  stringifyCoords,
} from './utils';
import { formatMeters, formatSeconds } from './utils/format';

type EnhancedPoint = {
  point: [number, number];
  id?: string;
  label?: string | number;
  markerSyle?: CSSProperties;
};

export function UnitPlanForm({
  id,
  workOrders,
  unitStartingPoint,
  addReturnPoint,
  addStartingPoint,
}: {
  id: string;
  workOrders: WorkOrder[];
  unitStartingPoint?: GPSPoint;
  addStartingPoint?: boolean;
  addReturnPoint?: boolean;
}) {
  const geoJsonRef = useRef<LeafletGeoJSON>(null);

  const [legs, setLegs] = useState<Leg[]>([]);
  const [points, setPoints] = useState<EnhancedPoint[]>([]);

  useEffect(() => {
    if (!workOrders.length) {
      return;
    }

    let coords = stringifyCoords(workOrders);
    const startingPoint =
      unitStartingPoint?.latitude && unitStartingPoint.longitude
        ? unitStartingPoint
        : BRATISLAVA_COORDS_GPS_POINT;
    let points = [] as EnhancedPoint[];

    if (!addReturnPoint && !addStartingPoint) {
      points = getPointsWithId(workOrders);
      if (workOrders.length === 1) {
        coords = addStartingPointCoords(
          coords,
          BRATISLAVA_COORDS_GPS_POINT,
          'start'
        );
      }
    } else {
      points = getPointsWithId(workOrders);
      if (addStartingPoint) {
        points = [
          {
            point: [startingPoint.latitude!, startingPoint.longitude!],
            label: 'S',
            markerSyle: { borderColor: 'blue' },
          },
          ...points,
        ];
        coords = addStartingPointCoords(coords, startingPoint, 'start');
      }
      if (addReturnPoint) {
        points = [
          ...points,
          {
            point: [startingPoint.latitude!, startingPoint.longitude!],
            label: 'S',
            markerSyle: { borderColor: 'blue' },
          },
        ];
        coords = addStartingPointCoords(coords, startingPoint, 'end');
      }
    }

    setPoints(points);

    getDrivingInfo(coords).then((data) => {
      geoJsonRef.current?.clearLayers().addData(data.geometry);

      setLegs(data.legs);
    });
  }, [workOrders, unitStartingPoint, id, addStartingPoint, addReturnPoint]);

  return (
    <PromptProvider>
      <div
        style={{
          display: 'flex',
        }}
      >
        <div style={{ width: 900, height: 500 }}>
          <MapContainer>
            <BasicTileLayer />
            <GeoJSON ref={geoJsonRef} />
            {points.map((point, index) => (
              <WorkOrderMarker
                key={index}
                label={`${point.label ?? ''}`}
                point={point.point}
                workOrderId={point.id}
                markerSyle={point.markerSyle}
              />
            ))}
          </MapContainer>
        </div>
        <div style={{ maxHeight: 500, overflow: 'auto' }}>
          {legs.map((leg, index) => (
            <Panel
              key={index}
              label={`Trasa ${points[index].label ?? index + 1}  ... ${
                points[index + 1]?.label ?? index + 2
              }`}
            >
              <div style={{ width: '300px' }}>
                <Typography variant="body1" style={{ marginLeft: 16 }}>
                  <b style={{ display: 'inline-block', width: 100 }}>Čas:</b>
                  {formatSeconds(leg.duration)}
                </Typography>
                <Typography variant="body1" style={{ marginLeft: 16 }}>
                  <b style={{ display: 'inline-block', width: 100 }}>
                    Vzdialenosť:
                  </b>
                  {formatMeters(leg.distance)}
                </Typography>
              </div>
            </Panel>
          ))}
        </div>
      </div>
    </PromptProvider>
  );
}
