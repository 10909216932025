import { DndContext } from '@dnd-kit/core';
import { snapCenterToCursor } from '@dnd-kit/modifiers';
import cn from 'clsx';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import React, { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import RefreshIcon from '@material-ui/icons/Refresh';
import SettingsIcon from '@material-ui/icons/Settings';
import { TableHandle } from '@eas/common-web';
import { PinnedMapContextProvider } from '@composite/map/pinned-map-context';
import {
  ClearCacheHandler,
  WebsocketCacheControlContext,
} from '@composite/websocket/websocket-cache-control-context';
import { WorkOrder } from '@models';
import { CalendarDimensions } from '@enums';
import { useAutoplannig } from './autoplanner/autoplanner';
import { useCalamity } from './calamity/calamity';
import { Calendar } from './calendar/calendar';
import { useCalendarDataSource } from './calendar/calendar-data-source';
import { CalendarHandle } from './calendar/calendar-types';
import { useDragEnd } from './dnd/hooks/drag-end';
import { useDragStart } from './dnd/hooks/drag-start';
import { rectIntersection } from './dnd/utils/collision-detection';
import { snapToRow } from './dnd/utils/snap-to-row';
import { PlannerCalendarPanelsLayout } from './planner-calendar-panels-layout';
import { PlannerContext } from './planner-context';
import { useSettings } from './settings/settings';
import { useWorkOrderDataSource } from './work-order-table/work-order-data-source';

dayjs.extend(duration);

export function Planner() {
  const calendarClasses = Calendar.useStyles();
  const calendarRef = useRef<CalendarHandle>(null);
  const clearTableWebsocketCacheRef = useRef<ClearCacheHandler>(null);
  const workOrderTableRef = useRef<TableHandle<WorkOrder>>(null);

  const [movingWorkOrders, setMovingWorkOrders] = useState<WorkOrder[]>([]);
  const [edgeWorkOrders, setEdgeWorkOrders] = useState<
    [WorkOrder | undefined, WorkOrder | undefined]
  >([undefined, undefined]);

  const [highlightedWorkOrder, setHighlightedWorkOrder] = useState<WorkOrder>();

  const calendarSource = useCalendarDataSource(
    calendarRef.current?.currentDate ?? Date.now() / 1000,
    calendarRef.current?.settings.dimension,
    calendarRef.current?.clearWebsocketCachedData?.current,
    movingWorkOrders
  );

  const workOrderSource = useWorkOrderDataSource();

  const [dragging, setDragging] = useState(false);

  const handleDragStart = useDragStart({
    setMovingWorkOrders,
  });

  const handleDragEnd = useDragEnd({
    calendarRef,
    workOrderSource,
    calendarSource,
    edgeWorkOrders,
    setEdgeWorkOrders,
    setMovingWorkOrders,
    clearTableWebsocketCacheRef,
  });

  const { handleClick: handleAutoplanner } = useAutoplannig({
    workOrderSource,
    calendarSource,
    defaultStart:
      calendarRef.current?.settings.dimension === CalendarDimensions.DAY
        ? calendarRef.current?.currentDate
        : undefined,
  });

  const { handleClick: handleCalamity } = useCalamity({
    calendarSource,
  });

  const { handleClick: handleSettings } = useSettings({
    calendarRef,
  });

  return (
    <>
      <WebsocketCacheControlContext.Provider
        value={{ table: clearTableWebsocketCacheRef }}
      >
        <DndContext
          modifiers={[snapCenterToCursor, snapToRow]}
          collisionDetection={rectIntersection}
          onDragStart={(event) => {
            setDragging(true);
            handleDragStart(event);
          }}
          onDragEnd={(event) => {
            setDragging(false);
            handleDragEnd(event);
          }}
          autoScroll={false}
        >
          <PlannerContext.Provider
            value={{
              calendarSource,
              workOrderSource,
              calendarRef,
              workOrderTableRef,
              highlightedWorkOrder,
              setHighlightedWorkOrder,
            }}
          >
            <div className={calendarClasses.topBar}>
              <Typography variant="h1" className={calendarClasses.title}>
                <FormattedMessage
                  id="ZSD__CALENDAR__TITLE"
                  defaultMessage="Plánovanie pracovných príkazov"
                />
              </Typography>

              <div className={calendarClasses.group}>
                <Button
                  variant="outlined"
                  className={cn(
                    calendarClasses.button,
                    calendarClasses.outlineButton
                  )}
                  onClick={handleAutoplanner}
                >
                  <FormattedMessage
                    id="ZSD__CALENDAR__AUTOMATIC__PLANNING"
                    defaultMessage="Automatické plánovanie"
                  />
                </Button>
                <Button
                  variant="outlined"
                  className={cn(
                    calendarClasses.button,
                    calendarClasses.basicButton
                  )}
                  onClick={handleCalamity}
                  title="Kalamita"
                >
                  <FlashOnIcon />
                </Button>

                <Button
                  variant="outlined"
                  className={cn(
                    calendarClasses.button,
                    calendarClasses.basicButton
                  )}
                  onClick={handleSettings}
                  title="Nastavenia"
                >
                  <SettingsIcon />
                </Button>
                <Button
                  variant="outlined"
                  className={cn(
                    calendarClasses.button,
                    calendarClasses.basicButton
                  )}
                  onClick={() => {
                    calendarSource.yAxis.refreshData();
                    workOrderTableRef.current?.refresh();
                    clearTableWebsocketCacheRef?.current?.();
                  }}
                  title="Obnoviť"
                >
                  <RefreshIcon />
                </Button>
              </div>
            </div>
            <PinnedMapContextProvider>
              <PlannerCalendarPanelsLayout
                dragging={dragging}
                movingWorkOrders={movingWorkOrders}
                setEdgeWorkOrders={setEdgeWorkOrders}
                workOrderSource={workOrderSource}
                ref={calendarRef}
              />
            </PinnedMapContextProvider>
          </PlannerContext.Provider>
        </DndContext>
      </WebsocketCacheControlContext.Provider>
    </>
  );
}
