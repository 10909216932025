import { GeoJSON as LeafletGeoJSON } from 'leaflet';
import React, { useContext, useRef } from 'react';
import { BRATISLAVA_COORDS_GPS_POINT } from './container/constants';
import { MapContainer } from './container/map-container';
import { WorkOrderMarker } from './markers/work-order-marker';
import { PinnedMapContext, PointMapData } from './pinned-map-context';
import { BasicTileLayer } from './tile-layers/basic-tile-layer';
import { GeoJSON } from './vector-layers/geo-json';

export function MapWithMarkers({ markers }: { markers: PointMapData[] }) {
  const geoJsonRef = useRef<LeafletGeoJSON>(null);
  const ctx = useContext(PinnedMapContext);
  return (
    <MapContainer
      key={`MAP_CONTAINER_KEY_${ctx?.mapContainerKey ?? 'default'}`}
    >
      <BasicTileLayer />
      <GeoJSON ref={geoJsonRef} />
      {markers.map(({ id, tooltip, pointLabel, point, pointStyle }, index) => (
        <WorkOrderMarker
          key={`marker-${index}`}
          workOrderId={id}
          tooltip={tooltip}
          label={pointLabel ?? ''}
          point={[
            point?.latitude ?? BRATISLAVA_COORDS_GPS_POINT.latitude!,

            point?.longitude ?? BRATISLAVA_COORDS_GPS_POINT.longitude!,
          ]}
          markerSyle={{ ...pointStyle }}
        />
      ))}
    </MapContainer>
  );
}
