import React, { useContext, useMemo } from 'react';
import { CalendarContext } from '@modules/planner/calendar/calendar-context';
import { CalendarDataType } from '@modules/planner/calendar/calendar-types';
import { getScaleItems } from '@modules/planner/calendar/utils';
import { getDailyTemplate, getIntervals } from '@composite/calendar/utils';
import { ShiftInterval } from '@models';
import { useStyles } from './shift-styles';

export interface SingleDayShiftProps {
  barData: CalendarDataType;
  getBarStyle: (isBlocked: boolean) => React.CSSProperties;
  getShiftStyle: (
    shiftInterval: ShiftInterval,
    hour: number,
    multipleShifts?: boolean
  ) => React.CSSProperties;
}

export function SingleDayShift({
  barData,
  getBarStyle,
  getShiftStyle,
}: SingleDayShiftProps) {
  const { settings, currentDate } = useContext(CalendarContext);

  const classes = useStyles();

  const items = useMemo(() => {
    const scaleItems = getScaleItems(currentDate, settings, []);

    const todayIntervals =
      barData.availability?.length > 0
        ? [
            ...getIntervals(barData.shifts?.[0]),
            ...barData.availability[0].intervals,
          ]
        : getIntervals(barData.shifts?.[0]);

    const intervals = getDailyTemplate(
      scaleItems,
      getIntervals(
        barData.overflowShift,
        barData.shifts?.[0]?.isException ||
          barData.shifts?.[0]?.isBlockingException
      ),
      todayIntervals
    );

    return intervals;
  }, [
    barData.availability,
    barData.overflowShift,
    barData.shifts,
    currentDate,
    settings,
  ]);

  return (
    <>
      {items.map((shiftIntervals, hour) => {
        const isBlocked = !!barData.shifts?.[0]?.isBlockingException;
        return (
          <div
            key={hour}
            className={classes.step}
            style={getBarStyle(isBlocked)}
          >
            <div
              style={{
                position: 'absolute',
                height: '100%',
                width: '100%',
                zIndex: 1,
              }}
            />
            {shiftIntervals.map((shiftInterval, indervalIndex, all) => (
              <div
                key={indervalIndex}
                style={{
                  position: 'absolute',
                  height: '100%',
                  ...getShiftStyle(shiftInterval, hour, all.length > 1),
                }}
              />
            ))}
          </div>
        );
      })}
    </>
  );
}
