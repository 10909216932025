import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Component,
  MuzaFile,
  TechnicalObject,
} from 'src/models/technical-object';
import Button from '@material-ui/core/Button';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import {
  EvidenceContext,
  FormPanel,
  FormTableField,
  FormTextField,
  useFormSelector,
} from '@eas/common-web';
import { ActivityField } from '@composite/activity-collection/activity-field';
import { DefectField } from '@composite/defect-collection/defect-field';
import { MismatchField } from '@composite/mismatch-collection/mismatch-field';
import { OperationField } from '@composite/operation-collection/operation-field';
import {
  ACTIVITIES_SOURCE,
  DEFECTS_SOURCE,
  MISMATCH_SOURCE,
  OPERATIONS_SOURCE,
} from './technical-object-hooked-sources';
import TechnicalObjectMapView from './technical-object-map-view';

export function Fields() {
  const { hookedSources } = useContext(EvidenceContext);

  const { noCoordinates } = useFormSelector((data: TechnicalObject) => ({
    noCoordinates: !data?.coordinates || (data?.coordinates?.length ?? 0) === 0,
  }));

  const activitySource = hookedSources[ACTIVITIES_SOURCE]?.source;
  const defectSource = hookedSources[DEFECTS_SOURCE]?.source;
  const operationSource = hookedSources[OPERATIONS_SOURCE]?.source;
  const mismatchSource = hookedSources[MISMATCH_SOURCE]?.source;

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ZSD__TECHNICAL_OBJECT__DETAIL__PANEL__GENERAL"
            defaultMessage="Všeobecné"
          />
        }
      >
        <FormTextField
          name="fid"
          label={
            <FormattedMessage
              id="ZSD__TECHNICAL_OBJECT__DETAIL__FIELD_LABEL__NAME"
              defaultMessage="FID"
            />
          }
        />
        <FormTextField
          name="type.name"
          label={
            <FormattedMessage
              id="ZSD__TECHNICAL_OBJECT__DETAIL__FIELD_LABEL__TYPE"
              defaultMessage="Typ"
            />
          }
        />

        <FormTextField
          name="model"
          label={
            <FormattedMessage
              id="ZSD__TECHNICAL_OBJECT__DETAIL__FIELD_LABEL__MODEL"
              defaultMessage="Model"
            />
          }
        />

        <FormTextField
          name="deviceType"
          label={
            <FormattedMessage
              id="ZSD__TECHNICAL_OBJECT__DETAIL__FIELD_LABEL__DEVICE_TYPE"
              defaultMessage="Typ zariadenia"
            />
          }
        />
        <FormTextField
          name="deviceLabel"
          label={
            <FormattedMessage
              id="ZSD__TECHNICAL_OBJECT__DETAIL__FIELD_LABEL__DEVICE_LABEL"
              defaultMessage="Označenie zariadenia"
            />
          }
        />

        <FormTextField
          name="userRating"
          label={
            <FormattedMessage
              id="ZSD__TECHNICAL_OBJECT__DETAIL__FIELD_LABEL__USER_RATING"
              defaultMessage="Rating technika"
            />
          }
        />

        <MismatchField source={mismatchSource} />
        <ActivityField source={activitySource} />
        <OperationField source={operationSource} />

        <DefectField source={defectSource} />
        <FormPanel
          defaultExpanded={true}
          expandable={true}
          label={
            <FormattedMessage
              id="ZSD__TECHNICAL_OBJECT__DETAIL__PANEL__COMPONENTS"
              defaultMessage="Komponenty"
            />
          }
        >
          <FormTableField<Component>
            labelOptions={{ hide: true }}
            layoutOptions={{ noSpacing: true }}
            showToolbar={false}
            name="components"
            showCheckboxCond={() => false}
            showDetailBtnCond={() => false}
            columns={[
              { datakey: 'name', name: 'Názov', width: 200 },
              { datakey: 'type.name', name: 'Typ', width: 200 },
              { datakey: 'type.type', name: 'Kód typu', width: 200 },
              {
                datakey: 'attributes',
                name: 'Atribúty',
                width: 300,
                CellComponent: (row) => (
                  <div>
                    {((row?.value as Component['attributes']) ?? [])
                      .map((v) => (v as any)?.value)
                      .join(', ')}
                  </div>
                ),
              },
            ]}
          />
        </FormPanel>
        <FormPanel
          defaultExpanded={true}
          expandable={true}
          label={
            <FormattedMessage
              id="ZSD__TECHNICAL_OBJECT__DETAIL__PANEL__MUZA_PHOTOS"
              defaultMessage="Fotografie"
            />
          }
        >
          <FormTableField<MuzaFile>
            labelOptions={{ hide: true }}
            layoutOptions={{ noSpacing: true }}
            showToolbar={false}
            name="photos"
            showCheckboxCond={() => false}
            showDetailBtnCond={() => false}
            columns={[
              { datakey: 'name', name: 'Názov', width: 200 },
              {
                datakey: 'cdnId',
                name: 'Stiahnuť',
                width: 200,
                CellComponent: (row) => (
                  <div>
                    <Button
                      variant="text"
                      disabled={!row?.value}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        window.open(
                          `/api/zsd/archived-file/cdn/${row?.value}/download`,
                          '_blank'
                        );
                      }}
                    >
                      Stiahnuť <OpenInNewIcon fontSize="small" />
                    </Button>
                  </div>
                ),
              },
            ]}
          />
        </FormPanel>

        <FormPanel
          defaultExpanded={true}
          expandable={true}
          label={
            <FormattedMessage
              id="ZSD__TECHNICAL_OBJECT__DETAIL__PANEL__MUZA_DOCUMENTS"
              defaultMessage="Dokumenty"
            />
          }
        >
          <FormTableField<MuzaFile>
            labelOptions={{ hide: true }}
            layoutOptions={{ noSpacing: true }}
            showToolbar={false}
            name="documents"
            showCheckboxCond={() => false}
            showDetailBtnCond={() => false}
            columns={[
              { datakey: 'name', name: 'Názov', width: 200 },
              {
                datakey: 'cdnId',
                name: 'Stiahnuť',
                width: 200,
                CellComponent: (row) => (
                  <div>
                    <Button
                      variant="text"
                      disabled={!row?.value}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        window.open(
                          `/api/zsd/archived-file/cdn/${row?.value}/download`,
                          '_blank'
                        );
                      }}
                    >
                      Stiahnuť <OpenInNewIcon fontSize="small" />
                    </Button>
                  </div>
                ),
              },
            ]}
          />
        </FormPanel>
      </FormPanel>

      {!noCoordinates && (
        <FormPanel
          defaultExpanded={false}
          expandable={true}
          label={
            <FormattedMessage
              id="ZSD__TECHNICAL_OBJECT__DETAIL__PANEL__MAP"
              defaultMessage="Zobrazenie TO na mape"
            />
          }
        >
          <TechnicalObjectMapView />
        </FormPanel>
      )}
    </>
  );
}
