import makeStyles from '@material-ui/core/styles/makeStyles';

// for MUI component
export const useTooltipWrapperStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: '#FEFFDE',
    color: 'black',
    border: '1px solid black',
    maxWidth: 700,
    textAlign: 'left',
    overflow: 'auto',
    fontSize: 14,
    whiteSpace: 'pre-wrap',
  },
}));

export const useTooltipStyles = makeStyles(() => ({
  content: {
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    padding: 0,
    marginTop: '3px',
    height: 'calc(100% - 4px)',
  },
  bullet: {
    width: 12,
    height: 12,
    borderRadius: 12,
    marginRight: 8,
    marginLeft: 14,
    flexShrink: 0,
  },
  tooltip: {
    whiteSpace: 'pre-line',
    display: 'flex',
    flexDirection: 'column',
    width: 'max-content',
  },
  tooltipContent: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
    justifyContent: 'space-between',
  },
}));
