import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DictionaryEvidence, FormPanel, FormTextField } from '@eas/common-web';

export function Fields() {
  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ZSD__TECHNICAL_OBJECT_TYPE__DETAIL__PANEL__GENERAL"
            defaultMessage="Všeobecné"
          />
        }
      >
        <DictionaryEvidence.FieldName />
        <FormTextField
          name="voltageLevel.name"
          label={
            <FormattedMessage
              id="ZSD__TECHNICAL_OBJECT_TYPE__DETAIL__FIELD__VOLTAGE_LEVEL"
              defaultMessage="Napätie"
            />
          }
        />
      </FormPanel>
    </>
  );
}
