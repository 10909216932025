import { TableCells, TableColumn } from '@eas/common-web';
import { TechnicalObject } from '@models';

export function useColumns(): TableColumn<TechnicalObject>[] {
  return [
    {
      datakey: 'fid',
      sortkey: 'fid',
      filterkey: 'fid',
      name: 'FID',
      width: 200,
      CellComponent: TableCells.TextCell,
    },
    {
      datakey: 'type.name',
      sortable: false,
      filterable: false,
      name: 'Typ',
      width: 200,
      CellComponent: TableCells.TextCell,
    },
  ];
}
