import clsx from 'clsx';
import { get } from 'lodash';
import React, { useContext, useRef } from 'react';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import {
  TableColumnAlign,
  TableColumnValueMapper,
  TableContext,
  TableRowProps,
  TableRowSelection,
  TableSelectedContext,
  useEventCallback,
} from '@eas/common-web';
import { TableRowHandlersContext } from '@composite/table/table-row-handlers-context';
import { CachedWorkOrder } from '@composite/websocket/listen-to-work-order-changes';
import { WorkOrder } from '@models';
import { Draggable } from '../dnd/draggable';
import {
  WorkOrderMenu,
  WorkOrderMenuHandle,
} from '../work-order-menu/work-order-menu';

const SELECTION_ID = 'row-selection';

export function WorkOrderRow({
  index,
  value,
  rowStyle,
}: TableRowProps<WorkOrder>) {
  const { filteredColumns, showSelectBox, sorts, classes, source } =
    useContext<TableContext<WorkOrder>>(TableContext);

  const { activeRow, selected } = useContext(TableSelectedContext);
  const { useRegisterRow } = useContext(TableRowHandlersContext);

  const [localValue, setLocalValue] = React.useState<WorkOrder>(value);

  useRegisterRow(value.id, (changedWorkOrder: CachedWorkOrder) =>
    setLocalValue(changedWorkOrder.data)
  );

  const workOrderMenuRef = useRef<WorkOrderMenuHandle>(null);

  const defaultValueMapper: TableColumnValueMapper<WorkOrder, any> =
    useEventCallback(({ value }) => value);

  const isRowSelected = selected.includes(value.id);
  const isItemSelected = (id: string) => selected.includes(id);
  const hasItemValidState = (id: string) => {
    const item = source.items.find((item) => item.id === id);
    return item?.currentState?.code === 'NOV';
  };

  const selectedData = source.items.filter((item) =>
    isRowSelected
      ? isItemSelected(item.id) && hasItemValidState(item.id)
      : item.id === value.id
  );

  // const prevWorkOrder = getPreviousWorkOrder(
  //   [...source.items, localValue],
  //   dayjs(localValue.startTime).unix()
  // );

  return (
    <>
      <Draggable
        id={isRowSelected ? SELECTION_ID : value.id}
        data={selectedData}
        disabled={localValue?.currentState?.code !== 'NOV'}
        handleRightClick={(e) => {
          workOrderMenuRef.current?.open(e);
        }}
      >
        <div
          style={{
            ...rowStyle,
          }}
          className={clsx(classes.row, {
            [classes.rowActive]: activeRow === value.id,
          })}
        >
          {showSelectBox && (
            <div
              className={classes.tableRowActions}
              onPointerDown={(e) => e.stopPropagation()}
              onKeyDown={(e) => e.stopPropagation()}
            >
              <TableRowSelection value={localValue} />
            </div>
          )}
          {filteredColumns.map((column, i) => {
            const { CellComponent, valueMapper = defaultValueMapper } = column;
            return (
              <div
                key={i}
                className={clsx(classes.cellWrapper, {
                  [classes.columnAlignLeft]:
                    column.align === TableColumnAlign.LEFT,
                  [classes.columnAlignRight]:
                    column.align === TableColumnAlign.RIGHT,
                  [classes.columnAlignCenter]:
                    column.align === TableColumnAlign.CENTER,
                })}
                style={{
                  width: column.width,
                  overflow: 'hidden',
                }}
              >
                <CellComponent
                  index={index}
                  value={valueMapper({
                    rowValue: localValue,
                    column,
                    value: get(
                      localValue,
                      column.displaykey ?? column.datakey,
                      null
                    ),
                    sorts,
                  })}
                  rowValue={localValue}
                  column={column}
                />
              </div>
            );
          })}
          <OpenWithIcon
            style={{ color: '#cfcdcd', width: '20px', height: '20px' }}
          />
        </div>
      </Draggable>
      <WorkOrderMenu
        workOrder={localValue}
        ref={workOrderMenuRef}
        stickToPointer={true}
        showInCalendarOption
        // previousWorkOrder={prevWorkOrder} // Robi problemy v kalendari, ked tak treba odlisit menu, alebo tuto polozku v kalendari a tabulke
      />
    </>
  );
}
