import React, { useContext, useMemo } from 'react';
import { CalendarContext } from '@modules/planner/calendar/calendar-context';
import { CalendarDataType } from '@modules/planner/calendar/calendar-types';
import { getMultiDayTemplate } from '@composite/calendar/utils/get-template';
import { ShiftInterval } from '@models';
import { useStyles } from './shift-styles';

export interface MultiDayShiftProps {
  barData: CalendarDataType;
  getBarStyle: (isBlocked: boolean) => React.CSSProperties;
  getShiftStyle: (
    shiftInterval: ShiftInterval,
    hour: number,
    multipleShifts?: boolean
  ) => React.CSSProperties;
}

export function MultiDayShift({
  barData,
  getBarStyle,
  getShiftStyle,
}: MultiDayShiftProps) {
  const classes = useStyles();

  const { settings, currentDate } = useContext(CalendarContext);

  const items = useMemo(() => {
    const shifts = getMultiDayTemplate(
      barData?.shifts ?? [],
      barData.overflowShift,
      settings,
      barData?.availability,
      currentDate
    );

    return shifts ?? [];
  }, [
    barData.overflowShift,
    barData?.shifts,
    barData?.availability,
    settings,
    currentDate,
  ]);

  return (
    <>
      {items.map((shift, index) => {
        const isBlocked = !!shift.isBlockingException;

        return (
          <div
            key={index}
            className={classes.step}
            style={getBarStyle(isBlocked)}
          >
            {shift.intervals.map((shiftIntervals, hour) => (
              <div
                key={hour}
                style={{
                  position: 'relative',
                  height: '100%',
                  width: '100%',
                }}
              >
                {!isBlocked &&
                  shiftIntervals.map((interval, index, all) => (
                    <div
                      key={index}
                      style={{
                        position: 'absolute' as const,
                        height: '100%',
                        ...getShiftStyle(interval, hour, all.length > 1),
                      }}
                    />
                  ))}
              </div>
            ))}
          </div>
        );
      })}
    </>
  );
}
