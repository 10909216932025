import React from 'react';
import { useIntl } from 'react-intl';
import { DatedEvidence, EmptyComponent, Evidence } from '@eas/common-web';
import { TechnicalObject } from '@models';
import { EvidenceApiUrl } from '@enums';
import { useColumns } from './technical-object-columns';
import { Fields } from './technical-object-fields';
import { useTechnicalObjectHookedSources } from './technical-object-hooked-sources';

export function TechnicalObjects() {
  const intl = useIntl();
  const columns = useColumns();

  const evidence = DatedEvidence.useDatedEvidence<TechnicalObject>({
    identifier: 'TECHNICAL_OBJECT',
    apiProps: {
      url: EvidenceApiUrl.TECHNICAL_OBJECTS,
      hookedSources: useTechnicalObjectHookedSources,
    },
    tableProps: {
      columns,
      tableName: intl.formatMessage({
        id: 'ZSD__TECHNICAL_OBJECT__TABLE__TITLE',
        defaultMessage: 'Technické objekty',
      }),
      showBulkActionButton: false,
      showReportButton: false,
      defaultSorts: [],
    },
    detailProps: {
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ZSD__TECHNICAL_OBJECT__DETAIL__TITLE',
          defaultMessage: 'Technický objekt',
        }),
        subtitle: (type) => type?.fid ?? '',
        showBtn: () => false,
      },
      GeneralFieldsComponent: EmptyComponent,
      FieldsComponent: Fields,
    },
  });

  return <Evidence {...evidence} />;
}
