import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DictionaryEvidence,
  FormNumberField,
  FormPanel,
  FormTextField,
} from '@eas/common-web';

export function Fields() {
  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ZSD__DEFECT_TYPE__DETAIL__PANEL__GENERAL"
            defaultMessage="Všeobecné"
          />
        }
      >
        <DictionaryEvidence.FieldName />
        <FormTextField
          name="technicalObjectType.name"
          label={
            <FormattedMessage
              id="ZSD__DEFECT_TYPE__DETAIL__FIELD__TECHNICAL_OBJECT_TYPE"
              defaultMessage="Typ technického objektu"
            />
          }
        />
        <FormNumberField
          name="severity"
          label={
            <FormattedMessage
              id="ZSD__DEFECT_TYPE__DETAIL__FIELD__SEVERITY"
              defaultMessage="Závažnosť"
            />
          }
        />
        <FormTextField
          name="component"
          label={
            <FormattedMessage
              id="ZSD__DEFECT_TYPE__DETAIL__FIELD__COMPONENT"
              defaultMessage="Komponent"
            />
          }
        />
        <FormTextField
          name="controlType.name"
          label={
            <FormattedMessage
              id="ZSD__DEFECT_TYPE__DETAIL__FIELD__CONTROL_TYPE"
              defaultMessage="Typ kontroly"
            />
          }
        />
      </FormPanel>
    </>
  );
}
