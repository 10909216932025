import dayjs from 'dayjs';
import { useEffect } from 'react';
import {
  ApiFilterOperation,
  Params,
  ResultDto,
  useFetch,
} from '@eas/common-web';
import {
  AvailabilityResult,
  Calendar,
  MobileUnit,
  WorkOrder,
  ZsdUser,
} from '@models';
import { CalendarDimensions, EvidenceApiUrl } from '@enums';

function prepareFilters(
  currentDay: number,
  dimmension?: CalendarDimensions,
  exact?: boolean
) {
  let startDate;
  let endDate;
  switch (dimmension) {
    case 'day':
      startDate = dayjs.unix(currentDay).format('YYYY-MM-DD');
      endDate = dayjs.unix(currentDay).add(1, 'day').format('YYYY-MM-DD');

      if (exact) {
        endDate = dayjs.unix(currentDay).format('YYYY-MM-DD');
      }
      break;
    case 'week':
      startDate = dayjs
        .unix(currentDay)
        .subtract(1, 'day')
        .format('YYYY-MM-DD');
      endDate = dayjs.unix(currentDay).add(6, 'day').format('YYYY-MM-DD');

      if (exact) {
        startDate = dayjs.unix(currentDay).format('YYYY-MM-DD');
        endDate = dayjs.unix(currentDay).add(7, 'day').format('YYYY-MM-DD');
      }
      break;
    case 'month':
      startDate = dayjs
        .unix(currentDay)
        .subtract(1, 'day')
        .format('YYYY-MM-DD');
      endDate = dayjs
        .unix(currentDay)
        .add(dayjs.unix(currentDay).daysInMonth() - 1, 'day')
        .format('YYYY-MM-DD');

      if (exact) {
        startDate = dayjs.unix(currentDay).format('YYYY-MM-DD');
        endDate = dayjs
          .unix(currentDay)
          .add(dayjs.unix(currentDay).daysInMonth(), 'day')
          .format('YYYY-MM-DD');
      }
      break;
    default:
      break;
  }

  return {
    startDate,
    endDate,
  };
}

interface SourceParams {
  mobileUnits: MobileUnit[];
  technicians: ZsdUser[];
  skip?: boolean;
  currentDay: number;
  dimmension?: CalendarDimensions;
}

export function useCalendarList({
  mobileUnits,
  technicians,
  skip,
  currentDay,
  dimmension,
}: SourceParams) {
  const { startDate, endDate } = prepareFilters(currentDay, dimmension);

  const params: Params = {
    filters: [
      {
        operation: ApiFilterOperation.OR,
        filters: [
          {
            operation: ApiFilterOperation.AND,
            filters: [
              {
                field: 'mobileUnit.id',
                operation: ApiFilterOperation.IN,
                values: mobileUnits?.map((mu) => mu.id),
              },
              {
                field: 'fromDate',
                operation: ApiFilterOperation.LTE,
                value: endDate,
              },
              {
                field: 'toDate',
                operation: ApiFilterOperation.GTE,
                value: startDate,
              },
            ],
          },
          {
            operation: ApiFilterOperation.AND,
            filters: [
              {
                field: 'user.id',
                operation: ApiFilterOperation.IN,
                values: technicians?.map((t) => t.id),
              },
              {
                field: 'fromDate',
                operation: ApiFilterOperation.LTE,
                value: endDate,
              },
              {
                field: 'toDate',
                operation: ApiFilterOperation.GTE,
                value: startDate,
              },
            ],
          },
        ],
      },
    ],
    size: -1,
  };

  const [result, loading, reset, setLoading] = useFetch<ResultDto<Calendar>>(
    `${EvidenceApiUrl.CALENDARS}/list`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      skip,
      body: JSON.stringify(params),
    }
  );

  return {
    result,
    loading,
    reset,
    setLoading,
  };
}

export function useWorkOrderList({
  mobileUnits,
  technicians,
  skip,
  currentDay,
  dimmension,
}: SourceParams) {
  const { startDate, endDate } = prepareFilters(currentDay, dimmension, true);
  const commonParams = [
    {
      field: 'startTime',
      operation: ApiFilterOperation.LTE,
      value: endDate,
    },
    {
      operation: ApiFilterOperation.OR,
      filters: [
        {
          field: 'endTime',
          operation: ApiFilterOperation.GTE,
          value: startDate,
        },
        {
          // this is needed to get work orders which are in "VYK" state and have endTime < startDate
          // condition startTime >= startDate should be still applied however
          operation: ApiFilterOperation.AND,
          filters: [
            {
              field: 'startTime',
              operation: ApiFilterOperation.GTE,
              value: startDate,
            },
            {
              field: 'currentState.code',
              operation: ApiFilterOperation.EQ,
              value: 'VYK',
            },
          ],
        },
      ],
    },
    {
      field: 'currentState',
      operation: ApiFilterOperation.NOT_NULL,
    },
    {
      operation: ApiFilterOperation.NOT,
      filters: [
        {
          field: 'currentState.code',
          operation: ApiFilterOperation.EQ,
          value: 'ZRU',
        },
      ],
    },
  ];

  const params: Params = {
    filters: [
      {
        operation: ApiFilterOperation.OR,
        filters: [
          {
            operation: ApiFilterOperation.AND,
            filters: [
              {
                field: 'mobileUnit.id',
                operation: ApiFilterOperation.IN,
                values: mobileUnits?.map((mu) => mu.id),
              },

              ...commonParams,
            ],
          },
          {
            operation: ApiFilterOperation.AND,
            filters: [
              {
                field: 'user.id',
                operation: ApiFilterOperation.IN,
                values: technicians?.map((t) => t.id),
              },
              ...commonParams,
            ],
          },
        ],
      },
    ],
    size: -1,
  };

  const [result, loading, reset, setLoading] = useFetch<ResultDto<WorkOrder>>(
    `${EvidenceApiUrl.WORK_ORDERS}/list`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      skip,
      body: JSON.stringify(params),
    }
  );

  return {
    result,
    loading,
    reset,
    setLoading,
  };
}

export function useAvailabilityList({
  mobileUnits,
  technicians,
  skip,
  workOrder,
  currentDay,
  dimmension,
}: SourceParams & {
  workOrder: WorkOrder | undefined;
}) {
  const { startDate, endDate } = prepareFilters(currentDay, dimmension, true);

  const params = {
    from: startDate,
    to: endDate,
    calendarOwners: [
      ...mobileUnits.map((mu) => ({
        id: mu.id,
        type: 'MOBILE_UNIT',
      })),
      ...technicians.map((t) => ({
        id: t.id,
        type: 'TECHNICIAN',
      })),
    ],
    size: -1,
  };

  const [result, loading, reset, setLoading] = useFetch<AvailabilityResult[]>(
    `${EvidenceApiUrl.WORK_ORDERS}/${workOrder?.id}/interval`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      skip,
      body: JSON.stringify(params),
    }
  );

  useEffect(() => {
    if (skip) {
      reset(true);
    }
  }, [skip]);

  return {
    result,
    loading,
    reset,
    setLoading,
  };
}

export function useCalendarExceptionList({
  mobileUnits,
  technicians,
  skip,
  currentDay,
  dimmension,
}: SourceParams) {
  const { startDate, endDate } = prepareFilters(currentDay, dimmension);

  const params: Params = {
    filters: [
      {
        operation: ApiFilterOperation.OR,
        filters: [
          {
            operation: ApiFilterOperation.AND,
            filters: [
              {
                field: 'mobileUnit.id',
                operation: ApiFilterOperation.IN,
                values: mobileUnits?.map((mu) => mu.id),
              },
              {
                field: 'validFrom',
                operation: ApiFilterOperation.LTE,
                value: endDate,
              },
              {
                field: 'validTo',
                operation: ApiFilterOperation.GTE,
                value: startDate,
              },
            ],
          },
          {
            operation: ApiFilterOperation.AND,
            filters: [
              {
                field: 'user.id',
                operation: ApiFilterOperation.IN,
                values: technicians?.map((t) => t.id),
              },
              {
                field: 'validFrom',
                operation: ApiFilterOperation.LTE,
                value: endDate,
              },
              {
                field: 'validTo',
                operation: ApiFilterOperation.GTE,
                value: startDate,
              },
            ],
          },
        ],
      },
    ],
    size: -1,
  };

  const [result, loading, reset, setLoading] = useFetch<ResultDto<Calendar>>(
    `${EvidenceApiUrl.CALENDAR_EXCEPTIONS}/list`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      skip,
      body: JSON.stringify(params),
    }
  );

  return {
    result,
    loading,
    reset,
    setLoading,
  };
}

export function useAbsenceList({
  mobileUnits,
  technicians,
  skip,
  currentDay,
  dimmension,
}: SourceParams) {
  const { startDate, endDate } = prepareFilters(currentDay, dimmension, true);

  const params: Params = {
    filters: [
      {
        field: 'user.id',
        operation: ApiFilterOperation.IN,
        values: technicians?.map((t) => t.id),
      },
      {
        field: 'fromDate',
        operation: ApiFilterOperation.LTE,
        value: endDate,
      },
      {
        field: 'toDate',
        operation: ApiFilterOperation.GTE,
        value: startDate,
      },
    ],
    size: -1,
  };

  const [result, loading, reset, setLoading] = useFetch<ResultDto<Calendar>>(
    `${EvidenceApiUrl.ABSENCES}/list`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      skip,
      body: JSON.stringify(params),
    }
  );

  return {
    result,
    loading,
    reset,
    setLoading,
  };
}
