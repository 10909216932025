import React, { useContext, useMemo } from 'react';
import Button from '@material-ui/core/Button';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import LaunchIcon from '@material-ui/icons/Launch';
import {
  PromptContext,
  TableContext,
  TableSelectedContext,
  TableToolbarButton,
  usePrompts,
} from '@eas/common-web';
import { unplanWorkOrder } from '@modules/work-order/work-order-api';
import { PlannerContext } from '../planner-context';

const validStatesForUnplan = Object.fromEntries(
  ['NAP', 'ODL', 'ODM'].map((e) => [e, true])
);

export function BulkUnplanSelectedWorkOrders({
  clearCache,
}: {
  clearCache?: () => void;
}) {
  const { selected } = useContext(TableSelectedContext);
  const { source } = useContext(TableContext);
  const { calendarSource, workOrderSource } = useContext(PlannerContext);
  const { testPrompt } = useContext(PromptContext);

  const { enabled, details } = useMemo(() => {
    if (selected.length < 1 || !source || source.items.length < 1) {
      return { enabled: false, details: [] };
    }
    const selectedDetails = (selected ?? []).map((id) => {
      return source?.items?.find((item) => item?.id === id);
    });
    if (
      selectedDetails?.some(
        (wo) => !validStatesForUnplan[wo?.currentState?.code]
      )
    ) {
      return { enabled: false, details: [] };
    }

    return { enabled: true, details: selectedDetails };
  }, [selected, source]);

  usePrompts(
    [
      {
        key: 'BULK_UNPLAN_WORK_ORDERS',
        dialogTitle: 'Odplánovať označené pracovné príkazy',
        dialogText: (
          <>
            Naozaj chcete odplánovať označené pracovné príkazy? <br />
            <b>Príkazy ktoré budú odplánované: </b>
            <br />
            {details.map((wo) => (
              <Button
                key={wo.id}
                onClick={() =>
                  window.open(`/zsd/pracovne-prikazy/${wo.id}`, '_blank')
                }
              >
                <span style={{ marginRight: 8, fontWeight: 'bold' }}>
                  {wo.workOrderId ?? wo.id}
                </span>
                <LaunchIcon fontSize="small" />
              </Button>
            ))}
          </>
        ),
        dialogWidth: 400,
      },
    ],
    [details]
  );

  if (!enabled) {
    return <></>;
  }

  return (
    <>
      <TableToolbarButton
        tooltip="Naraz odplánovať označené pracovné príkazy"
        label={
          <>
            <EventBusyIcon fontSize="small" />{' '}
            <span style={{ marginLeft: 4 }}>Odplánovať</span>
          </>
        }
        onClick={async () => {
          testPrompt({
            key: 'BULK_UNPLAN_WORK_ORDERS',
            submitCallback: async () => {
              const promises = selected.map((id) => unplanWorkOrder(id));
              await Promise.all(promises);
              clearCache?.();
              calendarSource?.yAxis?.refreshData();
              workOrderSource?.reset();
              workOrderSource?.loadMore();
            },
          });
        }}
      />
    </>
  );
}
