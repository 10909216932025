import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  mapWrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    borderRight: `1px solid ${theme.palette.grey[300]}`,
    position: 'relative',
  },
  customButtonsWrapper: {
    position: 'absolute',
    top: 10,
    right: 10,
    zIndex: 500,
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
}));

const positions = {
  topright: {
    top: 10,
    right: 10,
  },
  topleft: {
    top: 10,
    left: 10,
  },
  bottomright: {
    bottom: 10,
    right: 10,
  },
  bottomleft: {
    bottom: 10,
    left: 10,
  },
};

export const MapControlWrapper = ({
  children,
  position,
}: {
  children: React.ReactNode;
  position: 'topright' | 'topleft' | 'bottomright' | 'bottomleft';
}) => {
  const classes = useStyles();
  return (
    <div
      style={{ ...positions[position] }}
      className={classes.customButtonsWrapper}
    >
      {children}
    </div>
  );
};
