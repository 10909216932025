import React, { useMemo } from 'react';
import MuiTooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { getDuration } from '@composite/calendar/utils/get-duration';
import { getTaskInterval } from '@composite/calendar/utils/get-task-interval';
import { WorkOrder } from '@models';
import {
  useTooltipStyles,
  useTooltipWrapperStyles,
} from './task-tooltip-wrapper-styles';

export const TaskTooltip = ({
  workOrder,
  children,
}: {
  workOrder: WorkOrder;
  children: React.ReactNode;
}) => {
  const classes = useTooltipStyles();
  const tooltipWrapper = useTooltipWrapperStyles();
  const wrappedChildren = useMemo(
    () =>
      React.Children.toArray(children)
        .filter(React.isValidElement)
        .map((child) => React.cloneElement(child))[0],
    [children]
  );
  return (
    <MuiTooltip
      PopperProps={{
        disablePortal: false,
      }}
      interactive={false}
      classes={tooltipWrapper}
      title={
        <Typography component="div">
          <div className={classes.tooltip}>
            {workOrder.locked && (
              <div className={classes.tooltipContent}>
                <b>Uzamknutá</b>
              </div>
            )}

            {workOrder.workOrderId !== undefined && (
              <div className={classes.tooltipContent}>
                Číslo zákazky: <b># {workOrder.workOrderId}</b>
              </div>
            )}
            {workOrder.operationNumber && (
              <div className={classes.tooltipContent}>
                Číslo operácie: <b># {workOrder.operationNumber}</b>
              </div>
            )}
            <div className={classes.tooltipContent}>
              Dohodnuté so zákazníkom:
              <b> {workOrder.customerAgreed ? 'Áno' : 'Nie'} </b>
            </div>

            {workOrder.workOrderType && (
              <div className={classes.tooltipContent}>
                Druh práce:
                <b> {workOrder.workOrderType.name} </b>
              </div>
            )}

            <div className={classes.tooltipContent}>
              Stav:{' '}
              <div className={classes.content}>
                <div
                  className={classes.bullet}
                  style={{
                    backgroundColor: workOrder.currentState?.color,
                  }}
                />
                <b>{workOrder.currentState?.name}</b>
              </div>
            </div>
            {workOrder.dueDate && (
              <div className={classes.tooltipContent}>
                Realizácia do:
                <b>
                  {new Date(workOrder.dueDate).toLocaleDateString('sk-SK')}{' '}
                </b>
              </div>
            )}
            {workOrder.address && (
              <div className={classes.tooltipContent}>
                Mesto:
                <b>{workOrder.address.city}</b>
              </div>
            )}
            {workOrder.address && (
              <div className={classes.tooltipContent}>
                Ulica:
                <b>
                  <b>{workOrder.address.street}</b>
                </b>
              </div>
            )}
            {!!workOrder.duration &&
              !!workOrder.startTime &&
              !!workOrder.endTime && (
                <div className={classes.tooltipContent}>
                  Trvanie:{' '}
                  <b>
                    {getTaskInterval(workOrder.startTime, workOrder.endTime)} (
                    {getDuration(workOrder.duration)})
                  </b>
                </div>
              )}
            {!!workOrder.travelPrevious && (
              <div className={classes.tooltipContent}>
                Prejazd: <b>{getDuration(workOrder.travelPrevious)}</b>
              </div>
            )}
          </div>
        </Typography>
      }
    >
      {wrappedChildren}
    </MuiTooltip>
  );
};
